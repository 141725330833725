import { PropsWithChildren } from "react";
import { clsx, Container } from "@/components/ui";
import AMDBenefitsBlock from "@/components/AMDBenefitsBlock";

export type ContainerWithAmdBlockProps = PropsWithChildren & {
  className?: string;
};

export default function ContainerWithAmdBlock({ children, className }: ContainerWithAmdBlockProps) {
  return (
    <Container className="grid grid-cols-1 gap-10 py-10 lg:grid-cols-2">
      <div className={clsx("space-y-[48px] text-white lg:space-y-[54px] lg:pt-28", className)}>{children}</div>
      <div className="hidden justify-center lg:flex lg:justify-end">
        <AMDBenefitsBlock />
      </div>
    </Container>
  );
}
