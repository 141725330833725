import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useData } from "@/hooks/useData";
import { useGetTokenMutation } from "@/data/api";
import { GoogleAdsUrls } from "@/routes/urls";

export default function GoogleSignInCallback() {
  const navigate = useNavigate();
  const { token, setRefreshToken } = useData();
  const [getToken] = useGetTokenMutation();
  const urlParams = new URLSearchParams(window.location.search);
  // get the code that is the access code of user after authenticating and authorizing permission
  const googleAccessCode = urlParams.get("code");
  // get the state that is the anti-forgery state token
  const passThroughValue = urlParams.get("state");

  // if there is a code in the parameter of the url
  // send it to the backend with the state
  // where they will be used to get the refresh token
  useEffect(() => {
    if (googleAccessCode && token) {
      getToken({ token, googleAccessCode, passThroughValue })
        .unwrap()
        .then((data) => {
          if (data.refresh_token === 1) {
            setRefreshToken(1);
            // redirect user to the accessible accounts page
            navigate(GoogleAdsUrls.accounts);
          }
        });
    }
  }, [token, googleAccessCode, passThroughValue, navigate, setRefreshToken, getToken]);

  return null;
}
