import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { RadioField } from "@/components/ui/form";
import { FieldProps, FieldWrapper } from "./BaseField";

import { CardWithIcon } from "@/components/ui";

import { useChangeCampaignStatusMutation } from "@/data/api";
import { CampaignStatus, CampaignStatuses, CampaignStatusOptions } from "@/data/constants";
import { useCampaign } from "@/hooks/useCampaign";

const StateChangeActionMap: Record<CampaignStatus, string> = {
  Active: "Activating",
  Paused: "Pausing",
  Deleted: "Deleting"
};

const schema = yup.object({
  status: yup
    .string()
    .oneOf(Object.keys(CampaignStatuses) as CampaignStatus[])
    .required()
});

type FormData = {
  status: CampaignStatus;
};

export function CampaignStatusField({ active, handleNext, ...props }: FieldProps) {
  const { payload, campaignInfo } = useCampaign();
  const [messageWarning, setMessageWarning] = useState("");
  const [changeCampaignStatus, { isLoading }] = useChangeCampaignStatusMutation();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<FormData>({
    mode: "onChange",
    resolver: yupResolver(schema),
    values: {
      status: campaignInfo?.status as CampaignStatus
    }
  });

  const onSubmit = useCallback(
    ({ status }: FormData) => {
      if (status !== campaignInfo?.status) {
        setMessageWarning(`${StateChangeActionMap[status]} campaign... It can take a few seconds.`);
        changeCampaignStatus({ status, ...payload })
          .unwrap()
          .then(() => {
            setMessageWarning("");
            handleNext!();
          })
          .catch(() => {
            setMessageWarning("Error when trying to change status");
          });
      } else {
        handleNext!();
      }
    },
    [campaignInfo?.status, changeCampaignStatus, payload, handleNext]
  );

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FieldWrapper label="Check your campaign status" active={active} valid={isValid} {...props}>
          <RadioField options={CampaignStatusOptions} {...register("status")} error={errors.status?.message} />
        </FieldWrapper>
      </form>
      {messageWarning && (
        <CardWithIcon icon={isLoading ? "spinner" : "warning"} variant="warning" className="max-w-3xl">
          {messageWarning}
        </CardWithIcon>
      )}
    </>
  );
}
