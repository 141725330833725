import CreateCampaignLayout from "@/components/Campaigns/CreateCampaignLayout";
import BudgetStep from "@/components/Campaigns/CreateCampaign/BudgetStep";

export default function BudgetPage() {
  return (
    <CreateCampaignLayout>
      <BudgetStep />
    </CreateCampaignLayout>
  );
}
