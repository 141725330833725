import { useNavigate } from "react-router-dom";

import { ChatWizard } from "@/components/ChatWizard";
import {
  BusinessNameField,
  GoalField,
  IntroMessage,
  LandingPageField,
  LanguageCodeField,
  PhoneNumberField
} from "@/components/Campaigns/CreateCampaign/fields";
import { CampaignUrls } from "@/routes/urls";

export default function GeneralInfoStep() {
  const navigate = useNavigate();

  return (
    <>
      <ChatWizard
        steps={[GoalField, BusinessNameField, LandingPageField, LanguageCodeField, PhoneNumberField]}
        title="1. General Information"
        onFinish={() => navigate(CampaignUrls.geoLocation)}
      />
      <IntroMessage />
    </>
  );
}
