import React, { forwardRef } from "react";
import { Input, InputProps } from "@headlessui/react";

import { CommonControlStyles, FieldLayout, FieldLayoutProps } from "./field-layout";
import { clsx } from "../clsx";
import { CharacterCount } from "./character-count";

export type TextFieldProps = FieldLayoutProps &
  InputProps & {
    suffix?: React.ReactNode;
    append?: React.ReactNode;
    maxLength?: number;
    currentLength?: number;
  };

export const TextField = forwardRef(
  (
    {
      label,
      required,
      error,
      helpText,
      disabled,
      suffix,
      append,
      className,
      maxLength,
      value,
      ...props
    }: TextFieldProps,
    ref
  ) => {
    return (
      <FieldLayout disabled={disabled} label={label} required={required} error={error} helpText={helpText}>
        <div className="relative">
          {append && <div className="absolute left-3 top-0 translate-y-[42%]">{append}</div>}
          <Input
            ref={ref as React.Ref<HTMLInputElement>}
            type="text"
            className={clsx(CommonControlStyles, append && "pl-9", suffix && "pr-9", className)}
            {...props}
          />
          {suffix && <div className="absolute right-3 top-0 translate-y-[42%]">{suffix}</div>}
        </div>
        {maxLength && <CharacterCount value={value as string} maxLength={maxLength} />}
      </FieldLayout>
    );
  }
);
