import CreateCampaignLayout from "@/components/Campaigns/CreateCampaignLayout";
import LocationStep from "@/components/Campaigns/CreateCampaign/LocationStep";

export default function LocationPage() {
  return (
    <CreateCampaignLayout>
      <LocationStep />
    </CreateCampaignLayout>
  );
}
