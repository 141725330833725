import { useNavigate } from "react-router-dom";

import { ChatWizard } from "@/components/ChatWizard";
import { AdPreviewField, DescriptionsField, HeadlinesField } from "@/components/Campaigns/CreateCampaign/fields";
import { CampaignUrls } from "@/routes/urls";

export default function WriteSmartAdStep() {
  const navigate = useNavigate();

  return (
    <>
      <ChatWizard
        steps={[HeadlinesField, DescriptionsField, AdPreviewField]}
        title="4. Write Your Ad"
        onFinish={() => navigate(CampaignUrls.budget)}
      />
    </>
  );
}
