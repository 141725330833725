import CreateCampaignLayout from "@/components/Campaigns/CreateCampaignLayout";
import EditCampaignStep from "@/components/Campaigns/CreateCampaign/EditCampaign";

export default function EditCampaignPage() {
  return (
    <CreateCampaignLayout heading="Edit Campaign">
      <EditCampaignStep />
    </CreateCampaignLayout>
  );
}
