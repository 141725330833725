import { PropsWithChildren } from "react";
import { clsx } from "./clsx";

export type ContainerProps = PropsWithChildren & {
  id?: string;
  className?: string;
  as?: "div" | "section" | "header" | "main" | "footer";
  fluid?: boolean;
};

export function Container({ id, as: Component = "div", children, className, fluid }: ContainerProps) {
  return (
    <Component id={id} className={clsx(!fluid && "container mx-auto px-4", className)}>
      {children}
    </Component>
  );
}

type RectangleSectionVarian = "v1" | "v2";

export type RectangleSectionProps = PropsWithChildren & {
  circles?: boolean;
  variant?: RectangleSectionVarian;
  className?: string;
};

const CircleOnePositionStyles: Record<RectangleSectionVarian, string> = {
  v1: "-right-[200px] bottom-[587px] lg:-right-[151px] lg:bottom-[450px]",
  v2: "-right-[200px] bottom-[587px] lg:-right-[151px] lg:bottom-[450px]"
};

const CircleTwoPositionStyles: Record<RectangleSectionVarian, string> = {
  v1: "-bottom-[200px] left-[50%] lg:-bottom-[300px] lg:left-[50%]",
  v2: "-bottom-[200px] left-0 lg:-bottom-[300px] lg:left-0"
};

const CommonCirclesStyles = clsx("z-1 absolute", "bg-gradient-radial from-amd-pink to-amd-purple");

export function RectangleSection({ children, circles = true, variant = "v1", className }: RectangleSectionProps) {
  return (
    <div className={clsx("relative", className)}>
      <div className={clsx("relative overflow-hidden", variant === "v2" && "bg-dimmed-purple")}>
        {variant === "v1" && (
          <>
            <img
              src="/rectangle-bg.svg"
              alt="Rectangle background"
              className={clsx("z-1 absolute bottom-0 hidden w-full md:block 2xl:top-[27%]")}
            />
            <img
              src="/rectangle-bg.mob.svg"
              alt="Rectangle background mob"
              className="z-1 absolute bottom-0 w-full md:hidden"
            />
          </>
        )}
        <div className="relative z-20">{children}</div>
      </div>
      {circles && (
        <>
          <div
            className={clsx(
              CommonCirclesStyles,
              "h-[300px] w-[384px] -translate-y-[50%] rounded-[384px] blur-[150px]",
              CircleOnePositionStyles[variant]
            )}
          />
          <div
            className={clsx(
              CommonCirclesStyles,
              "h-[202px] w-[259px] -translate-x-[50%] -translate-y-[50%] rounded-[259px] blur-[105px]",
              "lg:h-[300px] lg:w-[384px] lg:rounded-[384px] lg:blur-[150px]",
              CircleTwoPositionStyles[variant]
            )}
          />
        </>
      )}
    </div>
  );
}

export function Divider({ children }: PropsWithChildren) {
  return (
    <div className="flex flex-row items-center justify-between gap-4">
      <div className="h-[1px] flex-grow border-b-2 border-b-amd-line" />
      <div className="max-w-[300px]">{children}</div>
      <div className="h-[1px] flex-grow border-b-2 border-b-amd-line" />
    </div>
  );
}
