import { useNavigate } from "react-router-dom";

import { ChatWizard } from "@/components/ChatWizard";
import {
  AdPreviewField,
  BudgetField,
  CampaignNameField,
  CampaignStatusField,
  KeywordThemesField,
  LocationField
} from "@/components/Campaigns/CreateCampaign/fields";
import { CampaignUrls } from "@/routes/urls";
import { useCampaign } from "@/hooks/useCampaign";
import { CardWithIcon } from "@/components/ui";
import AdPreviewBlock from "@/components/Campaigns/CreateCampaign/AdPreviewBlock";

export default function EditCampaignStep() {
  const navigate = useNavigate();
  const { isSuccess, isLoading, campaignInfo } = useCampaign();
  const { headline_1, headline_2, headline_3, desc_1, desc_2 } = campaignInfo || {};

  return (
    <>
      {isLoading || !isSuccess ? (
        <CardWithIcon icon="spinner" variant="warning">
          Fetching your data... It can take a few seconds.
        </CardWithIcon>
      ) : (
        <>
          {campaignInfo && (
            <AdPreviewBlock headlines={[headline_1, headline_2, headline_3]} descriptions={[desc_1, desc_2]} />
          )}
          <ChatWizard
            title="Add changes to campaign"
            steps={[
              CampaignStatusField,
              CampaignNameField,
              { Component: BudgetField, props: { saveImmediately: true } },
              { Component: KeywordThemesField, props: { saveImmediately: true } },
              { Component: LocationField, props: { saveImmediately: true } },
              { Component: AdPreviewField, props: { saveImmediately: true } }
            ]}
            onFinish={() => navigate(CampaignUrls.list)}
          />
        </>
      )}
    </>
  );
}
