import CreateCampaignLayout from "@/components/Campaigns/CreateCampaignLayout";
import WriteSmartAdStep from "@/components/Campaigns/CreateCampaign/WriteSmartAdStep";

export default function WriteSmartAdPage() {
  return (
    <CreateCampaignLayout>
      <WriteSmartAdStep />
    </CreateCampaignLayout>
  );
}
