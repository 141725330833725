import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useData } from "@/hooks/useData";
import { SelectField, TextField } from "@/components/ui/form";
import { FieldProps, FieldWrapper } from "./BaseField";
import { useAccountInfo } from "@/hooks/useAccountInfo";
import { CountryCodeOptions } from "@/data/constants";

const schema = yup
  .object({
    country_code: yup.string().required(),
    phone_number: yup.string().required()
  })
  .required();

type FormData = {
  country_code: string;
  phone_number: string;
};

export function PhoneNumberField({ handleNext, ...props }: FieldProps) {
  const { country_code, setCountry_code, phone_number, setPhone_number } = useData();
  const { phoneNumber } = useAccountInfo();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    values: {
      country_code,
      phone_number
    }
  });

  useEffect(() => {
    if (phoneNumber) {
      setValue("phone_number", phoneNumber);
    }
  }, [phoneNumber, setValue]);

  const onSubmit = useCallback(
    ({ country_code, phone_number }: FormData) => {
      setCountry_code(country_code);
      setPhone_number(phone_number);
      handleNext!();
    },
    [handleNext, setCountry_code, setPhone_number]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FieldWrapper label="5. Enter your business phone number" valid={isValid} {...props}>
        <div className="grid max-w-2xl grid-cols-2 gap-6">
          <SelectField
            options={[{ value: "", label: "Select country" }, ...CountryCodeOptions]}
            error={errors.country_code?.message}
            {...register("country_code")}
          />
          <TextField
            placeholder="Enter phone number of business"
            {...register("phone_number")}
            error={errors.phone_number?.message}
          />
        </div>
      </FieldWrapper>
    </form>
  );
}
