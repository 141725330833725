import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { CookiesProvider } from "react-cookie";

import { router } from "@/routes";
import { store } from "@/data/store";

import "unfonts.css";
import "./index.css";
import "./sentry";

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <Provider store={store}>
      <CookiesProvider>
        <RouterProvider router={router} />
      </CookiesProvider>
    </Provider>
  </StrictMode>
);
