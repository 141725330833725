import { PropsWithChildren } from "react";
import { clsx, SectionCard } from "@/components/ui";

export default function CongratulationCard({ children, className }: PropsWithChildren & { className?: string }) {
  return (
    <SectionCard className={clsx("space-y-4 text-center lg:p-5", className)}>
      <div className="text-[57px]">🎉</div>
      {children}
    </SectionCard>
  );
}
