import { Button, Container, SectionCard } from "@/components/ui";
import { Heading, Paragraph } from "@/components/ui/typography";
import Link from "@/components/Link";

export default function Benefits() {
  return (
    <div id="benefits" className="bg-light-purple py-[84px]">
      <Container className="relative z-20 space-y-[50px]">
        <Heading size="h1" as="h2" className="text-white">
          Benefits
        </Heading>
        <SectionCard className="space-y-9">
          <ul className="ml-4 list-outside list-disc space-y-1 lg:space-y-4">
            <li>
              <Paragraph as="span" size="u2">
                Intelligent ad creation
              </Paragraph>
            </li>
            <li>
              <Paragraph as="span" size="u2">
                Full budget control & cost transparency always
              </Paragraph>
            </li>
            <li>
              <Paragraph as="span" size="u2">
                No commitment. Pause, decease, increase, delete in a click. No worries
              </Paragraph>
            </li>
            <li>
              <Paragraph as="span" size="u2">
                No guesswork, Admin Dan is powered by intelligent Ad Al that gives you the unfair advantage
              </Paragraph>
            </li>
          </ul>
          <div>
            <Link to="/#plans">
              <Button variant="light" size="lg" className="w-full py-3 text-dark-purple lg:w-auto" rounded>
                See Plans
              </Button>
            </Link>
          </div>
        </SectionCard>
      </Container>
    </div>
  );
}
