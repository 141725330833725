import { DateFilterOption, DateOptionsMap } from "./types";
import { Select } from "@/components/ui/form/controls";

export type FilterByDateProps = {
  value?: string;
  onChange: (value: DateFilterOption) => void;
  emptyLabel?: string;
};

export default function FilterByDate({ value, onChange, emptyLabel }: FilterByDateProps) {
  return (
    <Select
      onChange={(event) => onChange(event.target.value as DateFilterOption)}
      value={value}
      aria-label="Filter table by date"
    >
      {emptyLabel && <option value="">{emptyLabel}</option>}
      {Object.entries(DateOptionsMap).map(([value, label]) => (
        <option value={value} key={value}>
          {label}
        </option>
      ))}
    </Select>
  );
}
