import type { RootState } from "@/data/store";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setToken } from "@/data/ui";
import {
  AccountInfo,
  BillingStatus,
  BudgetRecommendation,
  CampaignInfo,
  CreateAccountPayload,
  CreateSmartCampaignPayload,
  GetBudgetRecommendationsPayload,
  CrudCampaignPayload,
  GetCampaignsPayload,
  GetLocationRecommendationsPayload,
  GetTokenPayload,
  LinkAccountsPayload,
  LocationSuggestion,
  LookupRefreshToken,
  TokensPayload,
  StatusChange,
  EditAdCreativePayload,
  AdCreative,
  SearchTerm,
  KeywordRecommendationsPayload,
  ChangeCampaignKeywordsPayload,
  ChangeCampaignGeoTargetsPayload,
  ChangeCampaignSchedulePayload,
  ConnectResponse,
  CustomerID,
  AdRecommendationsPayload,
  AdRecommendations
} from "@/data/types";

const VITE_APP_API_ROOT = import.meta.env.VITE_APP_API_ROOT;

export type LoginInput = {
  username: string;
  password: string;
};

export const coreApi = createApi({
  reducerPath: "coreApi",
  baseQuery: fetchBaseQuery({
    baseUrl: VITE_APP_API_ROOT,
    prepareHeaders(headers, { getState }) {
      headers.set("Content-Type", "application/json");
      const token = (getState() as RootState).ui.token;
      if (token) {
        headers.set("authorization", `Token ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ["Account", "Campaign"],
  endpoints: (builder) => ({
    login: builder.mutation<{ token: string }, LoginInput>({
      query: (payload) => ({
        url: `/auth/`,
        method: "POST",
        body: payload
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(setToken(data.token));
        } catch (err) {
          console.log(err);
          dispatch(setToken(undefined));
        }
      }
    }),
    register: builder.mutation<void, LoginInput>({
      query: (payload) => ({
        url: `/api/users/`,
        method: "POST",
        body: payload
      })
    }),
    lookupRefreshToken: builder.query<LookupRefreshToken, { token: string }>({
      query: ({ token }) => ({
        url: `/api/lookup-refreshtoken/`,
        method: "POST",
        body: { mytoken: token }
      })
    }),
    connect: builder.mutation<ConnectResponse, void>({
      query: () => ({
        url: "/api/connect/"
        // responseHandler: (response) => response.text()
      })
    }),
    getToken: builder.mutation<LookupRefreshToken, GetTokenPayload>({
      query: ({ token, googleAccessCode, passThroughValue }) => ({
        url: `/api/get-token/`,
        method: "POST",
        body: {
          mytoken: token,
          google_access_code: googleAccessCode,
          passthrough_val: passThroughValue
        }
      })
    }),
    getCampaigns: builder.query<CampaignInfo[], GetCampaignsPayload>({
      query: ({ token, refreshToken, customerId, date }) => ({
        url: `/api/get-campaigns/`,
        method: "POST",
        body: {
          mytoken: token,
          refreshToken,
          customer_id: customerId,
          date_range: date
        }
      }),
      providesTags: ["Campaign"]
    }),
    getBilling: builder.query<{ status: BillingStatus }, GetCampaignsPayload>({
      query: ({ token, refreshToken, customerId, date }) => ({
        url: "/api/get-billing/",
        method: "POST",
        body: {
          mytoken: token,
          refreshToken,
          customer_id: customerId,
          date_range: date
        }
      })
    }),
    getAccounts: builder.query<AccountInfo[], TokensPayload>({
      query: ({ token, refreshToken }) => ({
        url: "/api/get-accounts/",
        method: "POST",
        body: {
          mytoken: token,
          refreshToken
        }
      }),
      providesTags: ["Account"]
    }),
    linkAccounts: builder.mutation<unknown, LinkAccountsPayload>({
      query: ({ token, refreshToken, customerId }) => ({
        url: `/api/link-accounts/`,
        method: "POST",
        body: {
          mytoken: token,
          refreshToken,
          customer_id: customerId
        }
      }),
      invalidatesTags: ["Account"]
    }),
    createAccount: builder.mutation<{ customer_id: CustomerID }, CreateAccountPayload>({
      query: ({ token, customerId, email, ...payload }) => ({
        url: `/api/create-account/`,
        method: "POST",
        body: {
          mytoken: token,
          customer_id: customerId,
          email_address: email,
          ...payload
        }
      }),
      invalidatesTags: ["Account"]
    }),
    getBudgetRecommendations: builder.query<BudgetRecommendation, GetBudgetRecommendationsPayload>({
      query: ({ token, refreshToken, customerId, ...payload }) => ({
        url: "/api/get-budget-recommendation/",
        method: "POST",
        body: {
          mytoken: token,
          refreshToken,
          customer_id: customerId,
          ...payload
        }
      })
    }),
    createSmartCampaign: builder.mutation<unknown, CreateSmartCampaignPayload>({
      query: ({ token, customerId, business_name, ...payload }) => ({
        url: `/api/create-campaign/`,
        method: "POST",
        body: {
          mytoken: token,
          customer_id: customerId,
          business_name,
          campaign_name: business_name,
          ...payload
        }
      }),
      invalidatesTags: ["Campaign"]
    }),
    getLocationRecommendations: builder.query<LocationSuggestion[], GetLocationRecommendationsPayload>({
      query: ({ token, refreshToken, customerId, location_input, ...payload }) => ({
        url: "/api/location-recommendations/",
        method: "POST",
        body: {
          mytoken: token,
          refreshToken,
          customer_id: customerId,
          location: location_input,
          ...payload
        }
      })
    }),
    // FIXME: null
    getBusinessInfo: builder.query<AccountInfo[] | null, { token: string }>({
      query: ({ token }) => ({
        url: `/api/get-business-info/`,
        method: "POST",
        body: {
          mytoken: token
        }
      })
    }),
    getCampaignSettings: builder.query<CampaignInfo[], CrudCampaignPayload>({
      query: ({ token, refreshToken, customerId, campaignId }) => ({
        url: `/api/get-campaign-settings/`,
        method: "POST",
        body: {
          mytoken: token,
          refreshToken,
          customer_id: customerId,
          campaign_id: campaignId
        }
      }),
      providesTags: (_, __, { campaignId }) => ["Campaign", { type: "Campaign", id: campaignId }]
    }),
    changeCampaignStatus: builder.mutation<void, CrudCampaignPayload & { status: StatusChange }>({
      query: ({ status, token, refreshToken, customerId, campaignId }) => ({
        url: {
          Active: "/api/sc-settings/enable/",
          Paused: "/api/sc-settings/pause/",
          Deleted: "/api/sc-settings/delete/"
        }[status],
        method: "POST",
        body: {
          mytoken: token,
          refreshToken,
          customer_id: customerId,
          campaign_id: campaignId
        }
      }),
      invalidatesTags: ["Campaign"]
    }),
    changeCampaignName: builder.mutation<CampaignInfo[], CrudCampaignPayload & { campaignName: string }>({
      query: ({ token, refreshToken, customerId, campaignId, campaignName }) => ({
        url: `/api/sc-settings/edit-name/`,
        method: "POST",
        body: {
          mytoken: token,
          refreshToken,
          customer_id: customerId,
          campaign_id: campaignId,
          campaign_name: campaignName
        }
      }),
      invalidatesTags: ["Campaign"]
    }),
    changeCampaignBudget: builder.mutation<
      CampaignInfo[],
      CrudCampaignPayload & {
        newBudget: string;
        budgetId: CampaignInfo["budget_id"];
      }
    >({
      query: ({ token, refreshToken, customerId, campaignId, newBudget, budgetId }) => ({
        url: `/api/sc-settings/edit-budget/`,
        method: "POST",
        body: {
          mytoken: token,
          refreshToken,
          customer_id: customerId,
          campaign_id: campaignId,
          new_budget: newBudget,
          budget_id: budgetId
        }
      }),
      invalidatesTags: ["Campaign"]
    }),
    changeCampaignKeywords: builder.mutation<string[], ChangeCampaignKeywordsPayload>({
      query: ({ token, customerId, campaignId, ...payload }) => ({
        url: "/api/sc-settings/edit-keywords/",
        method: "POST",
        body: {
          mytoken: token,
          customer_id: customerId,
          campaign_id: campaignId,
          ...payload
        }
      }),
      invalidatesTags: ["Campaign"]
    }),
    changeCampaignGeoTargets: builder.mutation<string[], ChangeCampaignGeoTargetsPayload>({
      query: ({ token, customerId, campaignId, ...payload }) => ({
        url: "/api/sc-settings/edit-geo-targets/",
        method: "POST",
        body: {
          mytoken: token,
          customer_id: customerId,
          campaign_id: campaignId,
          ...payload
        }
      }),
      invalidatesTags: ["Campaign"]
    }),
    editAdCreative: builder.mutation<AdCreative[], CrudCampaignPayload & EditAdCreativePayload>({
      query: ({ token, customerId, campaignId, ...payload }) => ({
        url: "/api/sc-settings/edit-ad-creative/",
        method: "POST",
        body: {
          mytoken: token,
          customer_id: customerId,
          campaign_id: campaignId,
          ...payload
        }
      }),
      invalidatesTags: ["Campaign"]
    }),
    getSearchTermReport: builder.query<SearchTerm[], CrudCampaignPayload & { date_range: string }>({
      query: ({ token, customerId, campaignId, ...payload }) => ({
        url: "/api/get-search-terms-report/",
        method: "POST",
        body: {
          mytoken: token,
          customer_id: customerId,
          campaign_id: campaignId,
          ...payload
        }
      })
    }),
    getNegativeKeywords: builder.query<string[], CrudCampaignPayload>({
      query: ({ token, customerId, campaignId, ...payload }) => ({
        url: "/api/get-negative-keywords/",
        method: "POST",
        body: {
          mytoken: token,
          customer_id: customerId,
          campaign_id: campaignId,
          ...payload
        }
      })
    }),
    changeNegativeKeywords: builder.mutation<string[], CrudCampaignPayload & { display_name: string }>({
      query: ({ token, customerId, campaignId, ...payload }) => ({
        url: "/api/edit-negative-keywords/",
        method: "POST",
        body: {
          mytoken: token,
          customer_id: customerId,
          campaign_id: campaignId,
          ...payload
        }
      }),
      invalidatesTags: ["Campaign"]
    }),
    getKeywordRecommendations: builder.query<string[], KeywordRecommendationsPayload>({
      query: ({ token, customerId, campaignId, ...payload }) => ({
        url: "/api/keywords-recommendations/",
        method: "POST",
        body: {
          mytoken: token,
          customer_id: customerId,
          ...(campaignId ? { campaign_id: campaignId } : null),
          ...payload
        }
      })
    }),
    getAdRecommendations: builder.query<AdRecommendations, AdRecommendationsPayload>({
      query: ({ token, customerId, campaignId, ...payload }) => ({
        url: "/api/get-ad-recommendation/",
        method: "POST",
        body: {
          mytoken: token,
          customer_id: customerId,
          ...(campaignId ? { campaign_id: campaignId } : null),
          ...payload
        }
      })
    }),
    changeCampaignSchedule: builder.mutation<void, ChangeCampaignSchedulePayload>({
      query: ({ token, customerId, campaignId, ...payload }) => ({
        url: "/api/sc-settings/edit-ad-schedule/",
        method: "POST",
        body: {
          mytoken: token,
          customer_id: customerId,
          campaign_id: campaignId,
          ...payload
        }
      }),
      invalidatesTags: ["Campaign"]
    })
  })
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useLookupRefreshTokenQuery,
  useConnectMutation,
  useGetTokenMutation,
  useGetCampaignsQuery,
  useGetBillingQuery,
  useGetAccountsQuery,
  useLinkAccountsMutation,
  useCreateAccountMutation,
  useGetBudgetRecommendationsQuery,
  useLazyGetBudgetRecommendationsQuery,
  useCreateSmartCampaignMutation,
  useLazyGetLocationRecommendationsQuery,
  useGetBusinessInfoQuery,
  useGetCampaignSettingsQuery,
  useChangeCampaignStatusMutation,
  useChangeCampaignNameMutation,
  useChangeCampaignBudgetMutation,
  useChangeCampaignGeoTargetsMutation,
  useEditAdCreativeMutation,
  useLazyGetSearchTermReportQuery,
  useLazyGetNegativeKeywordsQuery,
  useChangeNegativeKeywordsMutation,
  useLazyGetKeywordRecommendationsQuery,
  useChangeCampaignKeywordsMutation,
  useChangeCampaignScheduleMutation,
  useGetAdRecommendationsQuery
} = coreApi;
