import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useConnectMutation } from "@/data/api";
import { GoogleAdsUrls } from "@/routes/urls";

export function useAuthenticateGoogle() {
  const navigate = useNavigate();
  // create new Google Ads account (client, not manager)
  const [authenticateGoogle, { data, error }] = useConnectMutation();

  // if Url has a value, redirect user to that url
  // that is the url where the user will authenticate in Google and authorize your app
  useEffect(() => {
    if (data?.url) {
      window.location.href = data.url;
    }
  }, [data?.url]);

  if (error) {
    console.log(error);
  }

  return {
    Url: data?.url,
    authenticateGoogle,
    createAccount: () => navigate(GoogleAdsUrls.createAccount)
  };
}
