export enum DateFilterOption {
  TODAY = "TODAY",
  YESTERDAY = "YESTERDAY",
  THIS_WEEK_SUN_TODAY = "THIS_WEEK_SUN_TODAY",
  LAST_7_DAYS = "LAST_7_DAYS",
  LAST_14_DAYS = "LAST_14_DAYS",
  THIS_MONTH = "THIS_MONTH",
  LAST_30_DAYS = "LAST_30_DAYS",
  LAST_MONTH = "LAST_MONTH",
  ALL_TIME = "ALL_TIME",
}

export const DateOptionsMap: Record<DateFilterOption, string> = {
  [DateFilterOption.TODAY]: "Today",
  [DateFilterOption.YESTERDAY]: "Yesterday",
  [DateFilterOption.THIS_WEEK_SUN_TODAY]: "This week (Sun - Today)",
  [DateFilterOption.LAST_7_DAYS]: "Last 7 Days",
  [DateFilterOption.LAST_14_DAYS]: "Last 14 Days",
  [DateFilterOption.THIS_MONTH]: "This month",
  [DateFilterOption.LAST_30_DAYS]: "Last 30 days",
  [DateFilterOption.LAST_MONTH]: "Last month",
  [DateFilterOption.ALL_TIME]: "All time",
};
