import { Outlet } from "react-router-dom";

import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
import { Container } from "@/components/ui/layout";
import Background from "@/components/Background";
import ScrollToAnchor from "@/components/SchrollToAnchor";

export default function Layout() {
  return (
    <>
      <Background />
      <Container fluid className="relative z-10 flex min-h-screen flex-col overflow-x-hidden">
        <NavBar />
        <Container as="main" fluid>
          <Outlet />
        </Container>
        <div className="mt-auto">
          <Footer />
        </div>
      </Container>
      <ScrollToAnchor />
    </>
  );
}
