import { Container, RectangleSection } from "@/components/ui";
import Benefits from "@/components/HomePage/Benefits";
import Plans from "@/components/HomePage/Plans";
import HowItWorks from "@/components/HomePage/HowItWorks";
import ManagingAds from "@/components/HomePage/ManagingAds";
import DominateHero from "@/components/HomePage/DominateHero";
import ComingSoon from "@/components/HomePage/ComingSoon";
import ContactForm from "@/components/HomePage/ContactForm";
import GoogleSignInCallback from "@/components/auth/GoogleSignInCallback";

export default function HomePage() {
  return (
    <>
      <RectangleSection>
        <Container className="space-y-[32px] py-[50px] lg:space-y-[50px]">
          <DominateHero />
          <ManagingAds />
          <HowItWorks />
        </Container>
      </RectangleSection>
      <Benefits />
      <Plans />
      <ComingSoon />
      <ContactForm />
      <GoogleSignInCallback />
    </>
  );
}
