import { Container, FacebookIcon, InstagramIcon, LinkedinIcon, SectionCard, TiktokIcon, XIcon } from "@/components/ui";
import { Heading, Paragraph } from "@/components/ui/typography";
import { PropsWithChildren } from "react";

function IconCard({ children }: PropsWithChildren) {
  return (
    <SectionCard className="flex shrink-0 flex-col items-center gap-4 p-5 lg:min-w-[220px] lg:gap-[34px] lg:p-[50px]">
      {children}
    </SectionCard>
  );
}

export default function ComingSoon() {
  return (
    <div className="bg-light-purple py-[84px]">
      <Container id="benefits" className="relative z-20 space-y-[50px] text-white">
        <div>
          <Heading size="h1" as="h2" className="z-20">
            Coming Soon
          </Heading>
          <Paragraph size="u1" weight="semi" className="mt-[30px]">
            Soon you’ll be able to manage your ads access these platforms with Adman Dan.{" "}
          </Paragraph>
        </div>
        <div className="grid grid-cols-2 items-center gap-[15px] lg:grid-cols-5 lg:gap-[30px]">
          <IconCard>
            <FacebookIcon />
            <Heading.h2 as="div" className="font-semibold">
              Facebook
            </Heading.h2>
          </IconCard>
          <IconCard>
            <InstagramIcon />
            <Heading.h2 as="div" className="font-semibold">
              Instagram
            </Heading.h2>
          </IconCard>
          <IconCard>
            <TiktokIcon />
            <Heading.h2 as="div" className="font-semibold">
              Tiktok
            </Heading.h2>
          </IconCard>
          <IconCard>
            <LinkedinIcon />
            <Heading.h2 as="div" className="font-semibold">
              Linkedin
            </Heading.h2>
          </IconCard>
          <IconCard>
            <XIcon />
            <Heading.h2 as="div" className="font-semibold">
              X
            </Heading.h2>
          </IconCard>
        </div>
      </Container>
    </div>
  );
}
