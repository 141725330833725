import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useAppSelector } from "@/data/hooks";
import { selectIsAuthenticated } from "@/data/ui";
import { useLoginMutation, useRegisterMutation } from "@/data/api";

import { TextField, SubmitButton, PasswordField } from "@/components/ui/form";
import { Card } from "@/components/ui";

const GeneralErrorMessage = "Something went wrong, please try again";

const schema = yup
  .object({
    email: yup.string().email().required(),
    password: yup.string().required(),
    confirm: yup.string().oneOf([yup.ref("password"), undefined], "Passwords must match")
  })
  .required();

type FormData = {
  email: string;
  password: string;
  confirm?: string;
};

export default function SignUpForm() {
  const navigate = useNavigate();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  const [loginUser, { isLoading: loginLoading }] = useLoginMutation();
  const [registerUser, { isLoading: registerLoading }] = useRegisterMutation();
  const isLoading = loginLoading || registerLoading;

  const [errorMsg, setErrorMsg] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>({
    resolver: yupResolver(schema)
  });

  // if user has token, redirect user to googleads page
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/googleads");
    }
  }, [isAuthenticated, navigate]);

  const onSubmit = useCallback(
    async ({ email, password }: FormData) => {
      setErrorMsg("");
      try {
        await registerUser({ username: email, password }).unwrap();
        await loginUser({ username: email, password }).unwrap();
      } catch (err) {
        setErrorMsg(
          (err as { data: { non_field_errors: string[] } }).data.non_field_errors?.join("\n") || GeneralErrorMessage
        );
      }
    },
    [loginUser, registerUser]
  );

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)} className="space-y-[32px]">
      {errorMsg && <Card variant="warning">{errorMsg}</Card>}
      <TextField placeholder="Email" {...register("email")} error={errors.email?.message} />
      <PasswordField placeholder="Create Password" {...register("password")} error={errors.password?.message} />
      <PasswordField placeholder="Confirm Password" {...register("confirm")} error={errors.confirm?.message} />
      <SubmitButton isLoading={isLoading} variant="light" rounded className="w-full py-3">
        Sign Up
      </SubmitButton>
    </form>
  );
}
