import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useData } from "@/hooks/useData";
import { Currency } from "@/data/types";
import { useCreateAccountMutation } from "@/data/api";

import { SubmitButton, SelectField, TextField } from "@/components/ui/form";
import { CurrencyMap, CurrencyOptions, TimezoneOptions } from "@/data/constants";
import { CampaignUrls } from "@/routes/urls";
import { Button, Card, CardWithIcon } from "@/components/ui";
import CongratulationCard from "@/components/CongratulationCard";
import { Heading, Paragraph } from "@/components/ui/typography";

const schema = yup
  .object({
    account_name: yup.string().max(1000).required(),
    email: yup.string().email().required(),
    currency: yup.string().oneOf(Object.keys(CurrencyMap)).required(),
    time_zone: yup.string().required()
  })
  .required();

type FormData = {
  account_name: string;
  email: string;
  currency: Currency | string;
  time_zone: string;
};

export default function CreateGoogleAdsAccountForm() {
  const navigate = useNavigate();
  const [messageWarning, setMessageWarning] = useState("");
  const [messageSuccess, setMessageSuccess] = useState("");

  const { token, refreshToken, customerId, setCustomerId } = useData();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>({
    resolver: yupResolver(schema)
  });

  const [createAccount, { isLoading }] = useCreateAccountMutation();

  const onSubmit = (data: FormData) => {
    if (!token) {
      return;
    }
    createAccount({ token, refreshToken, customerId, ...data })
      .unwrap()
      .then(({ customer_id }) => {
        setCustomerId(customer_id);
        setMessageSuccess("Congratulations! You have created a Google Ads account.");
      })
      .catch((error) => {
        console.log(error);
        setMessageWarning("Failed to create account. Please try again");
      });
  };

  if (messageSuccess) {
    return (
      <CongratulationCard className="space-y-7">
        <Heading.h2 className="font-semibold">
          Congratulations!
          <br />
          You have created a Google Ads account.
        </Heading.h2>
        <div className="space-y-4 text-left">
          <Paragraph size="p3">One more step to go.</Paragraph>
          <Paragraph size="p3">
            Check for an email with the subject{" "}
            <strong>“You received an invitation to access a Google Ads account”</strong>, and accept the invitation.
          </Paragraph>
          <Paragraph size="p3">When you finish, close that window so you come back here and get started.</Paragraph>
        </div>
        <Button variant="light" rounded className="w-full" onClick={() => navigate(CampaignUrls.list)}>
          Start
        </Button>
      </CongratulationCard>
    );
  }

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)} className="space-y-4 lg:space-y-[38px]">
      {messageWarning && (
        <CardWithIcon icon="warning" variant="warning">
          {messageWarning}
        </CardWithIcon>
      )}
      <TextField placeholder="Account Name" {...register("account_name")} error={errors.account_name?.message} />
      <TextField type="email" placeholder="E-mail" {...register("email")} error={errors.email?.message} />
      <SelectField
        options={[{ value: "", label: "Select currency" }, ...CurrencyOptions]}
        {...register("currency")}
        error={errors.currency?.message}
      />
      <SelectField
        options={[{ value: "", label: "Select Time Zone" }, ...TimezoneOptions]}
        {...register("time_zone")}
        error={errors.time_zone?.message}
      />
      <SubmitButton variant="light" rounded isLoading={isLoading} className="w-full">
        Create
      </SubmitButton>
      {isLoading && <Card>We are creating your Google Ads account. It may take a few seconds.</Card>}
    </form>
  );
}
