import { useNavigate } from "react-router-dom";

import { ChatWizard } from "@/components/ChatWizard";
import { KeywordThemesField } from "@/components/Campaigns/CreateCampaign/fields";
import { CampaignUrls } from "@/routes/urls";

export default function KeywordThemesStep() {
  const navigate = useNavigate();

  return (
    <ChatWizard
      steps={[KeywordThemesField]}
      title="3. Select Keywords"
      onFinish={() => navigate(CampaignUrls.writeSmartAd)}
    />
  );
}
