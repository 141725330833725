import * as yup from "yup";

export const HEADLINES_MAX_LENGTH = 30;

export const headlinesSchema = yup
  .object({
    headline_1: yup.string().required().max(HEADLINES_MAX_LENGTH),
    headline_2: yup.string().required().max(HEADLINES_MAX_LENGTH),
    headline_3: yup.string().required().max(HEADLINES_MAX_LENGTH)
  })
  .required();

export type HeadlinesFormData = {
  headline_1: string;
  headline_2: string;
  headline_3: string;
};

export const DESC_MAX_LENGTH = 90;

export const descriptionsSchema = yup
  .object({
    desc_1: yup.string().required().max(DESC_MAX_LENGTH),
    desc_2: yup.string().required().max(DESC_MAX_LENGTH)
  })
  .required();

export type DescriptionsFormData = {
  desc_1: string;
  desc_2: string;
};
