import { Heading, Paragraph } from "@/components/ui/typography";
import { Button, SectionCard } from "@/components/ui";

export default function HowItWorks() {
  return (
    <>
      <Heading as="h2" size="h1" id="how-it-works" className="!mt-[61px] text-white lg:!mt-[152px]">
        How It Works?
      </Heading>
      <SectionCard className="space-y-9">
        <ul className="ml-4 list-outside list-disc space-y-1 lg:space-y-4">
          <li>
            <Paragraph as="span" size="u2">
              Link your existing ad account. No account, no problem! Dan will help you get setup right here in no time.
            </Paragraph>
          </li>
          <li>
            <Paragraph as="span" size="u2">
              Adman Dan guides you through the ad creation processing using a familiar interface you already know and
              love, chat.
            </Paragraph>
          </li>
          <li>
            <Paragraph as="span" size="u2">
              Using advanced Al and proprietary ad magic from Adman Dan's own personal experience, your ad suggestions
              are carefully crafted to your business. to maximise effectiveness.
            </Paragraph>
          </li>
          <li>
            <Paragraph as="span" size="u2">
              Set your campaign budget and Adman Dan takes care of the rest. No nasty surprises.
            </Paragraph>
          </li>
          <li>
            <Paragraph as="span" size="u2">
              As your ads run, Adman Dan continuously collects and evaluates your campaign performance across a range of
              metrics including your competitors. Once Dan has enough data you will begin to see suggested changes and
              enhancements. No change will ever be made without your explicit approval.
            </Paragraph>
          </li>
          <li>
            <Paragraph as="span" size="u2">
              Ad campaign so successful you're sold out and need to take a break? Pause ad spend in a simple click.
            </Paragraph>
          </li>
        </ul>
        <div>
          <Button variant="light" size="lg" className="w-full py-3 text-dark-purple lg:w-auto" rounded>
            Boost My Business
          </Button>
        </div>
      </SectionCard>
    </>
  );
}
