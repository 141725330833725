import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useData } from "@/hooks/useData";
import { TextField } from "@/components/ui/form";
import { FieldProps, FieldWrapper } from "./BaseField";
import FormFieldWrapper from "@/components/Campaigns/Forms/FieldWrapper";
import { useChangeCampaignGeoTargetsMutation, useLazyGetLocationRecommendationsQuery } from "@/data/api";
import { toTitleCase } from "@/utils/string";
import { Button, TagButton, CardWithIcon } from "@/components/ui";
import { Paragraph } from "@/components/ui/typography";
import { useCampaign } from "@/hooks/useCampaign";
import { hasDifference } from "@/utils/array";

const schema = yup
  .object({
    location: yup.string().required("Enter a location in the text box.")
  })
  .required();

type FormData = {
  location: string;
};

export type LocationFieldProps = FieldProps & {
  saveImmediately?: boolean;
};

export function LocationField({ handleNext, saveImmediately = false, ...props }: LocationFieldProps) {
  const [messageWarning, setMessageWarning] = useState("");
  const [location_targeting, setLocation_targeting] = useState<string[]>([]);

  const [getLocationRecommendations, { data: locationSuggestions = [] }] = useLazyGetLocationRecommendationsQuery();
  const [getAdditionalLocationRecommendations, { data: moreLocationSuggestions = [] }] =
    useLazyGetLocationRecommendationsQuery();
  const suggestions = [...new Set([...locationSuggestions, ...moreLocationSuggestions])];

  const { token, refreshToken, customerId, country_code, language_code, geo_location, setGeo_location } = useData();
  const { payload, requestAvailable, campaignInfo } = useCampaign();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues
  } = useForm<FormData>({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (geo_location) {
      try {
        setLocation_targeting(JSON.parse(JSON.stringify(geo_location)));
      } catch (e) {
        console.error(e);
      }
    }
  }, [geo_location]);

  const removeLocation = useCallback(
    (itemToRemove: string) => {
      setLocation_targeting(location_targeting.filter((el) => el !== itemToRemove));
    },
    [location_targeting]
  );

  const onSubmit = useCallback(
    ({ location }: FormData) => {
      if (!token || !customerId || refreshToken === undefined) {
        return;
      }
      const locationInput = toTitleCase(location);
      if (location_targeting.includes(locationInput)) {
        setMessageWarning(`You already added ${locationInput}.`);
        return;
      }
      setMessageWarning("");
      setLocation_targeting([...location_targeting, locationInput]);
      getLocationRecommendations({
        token,
        refreshToken,
        customerId,
        location_input: locationInput,
        country_code,
        language_code
      });
    },
    [country_code, customerId, getLocationRecommendations, language_code, location_targeting, refreshToken, token]
  );

  const addLocationRecommended = useCallback(
    (selected: string) => {
      const location_input = getValues("location");
      if (!token || !customerId || refreshToken === undefined || !location_input) {
        return;
      }
      if (!location_targeting.includes(selected)) {
        setLocation_targeting([...location_targeting, selected]);
      }
      getAdditionalLocationRecommendations({
        token,
        refreshToken,
        customerId,
        location_input,
        country_code,
        language_code
      });
    },
    [
      country_code,
      customerId,
      getAdditionalLocationRecommendations,
      getValues,
      language_code,
      location_targeting,
      refreshToken,
      token
    ]
  );

  const [changeCampaignGeoTargets, { isLoading }] = useChangeCampaignGeoTargetsMutation();
  const saveGeoLocations = useCallback(
    async (locations: string[]) => {
      if (!saveImmediately || !requestAvailable || !hasDifference(locations, campaignInfo?.geo_targets || [])) {
        return;
      }

      setMessageWarning("Changing the target locations... It can take a few seconds.");
      try {
        await changeCampaignGeoTargets({
          ...payload,
          new_geo_target_names: locations,
          country_code,
          language_code
        }).unwrap();
        setMessageWarning("");
      } catch (err) {
        console.log(err);
        setMessageWarning("Error when trying to change the target locations. Please try again.");
      }
    },
    [
      campaignInfo?.geo_targets,
      changeCampaignGeoTargets,
      country_code,
      language_code,
      payload,
      requestAvailable,
      saveImmediately
    ]
  );

  const goNext = () => {
    if (location_targeting.length > 0 || geo_location) {
      setGeo_location(location_targeting);
      saveGeoLocations(location_targeting).then(() => {
        handleNext!();
      });
    } else {
      setMessageWarning("Add at least one location, please.");
    }
  };

  return (
    <div className="space-y-[46px]">
      {messageWarning && (
        <CardWithIcon icon={isLoading ? "spinner" : "warning"} variant="warning">
          {messageWarning}
        </CardWithIcon>
      )}
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-[46px]">
        <FieldWrapper
          label="Your ads show to people physically or regularly in the locations you select, and to people who express interest in these locations."
          description="Enter country, state, province, city. Add 1 location at a time and add as many as you like."
          valid={location_targeting.length > 0}
          handleNext={goNext}
          nextSubmitButton={false}
          {...props}
        >
          <TextField {...register("location")} error={errors.location?.message} className="max-w-2xl" />
        </FieldWrapper>
        {props.active && (
          <Button variant="light" type="submit" rounded size="lg">
            Add & Recommend
          </Button>
        )}
      </form>
      {props.active && (
        <>
          {location_targeting.length > 0 && (
            <FormFieldWrapper label="Selected locations:">
              <div className="flex flex-row flex-wrap gap-3">
                {location_targeting.map((item, index) => (
                  <TagButton
                    key={`selected:${item}:${index}`}
                    onClick={() => removeLocation(item)}
                    className="px-5 py-2"
                  >
                    <Paragraph as="span" size="p2">
                      {item}
                    </Paragraph>
                    <XMarkIcon className="size-5" />
                  </TagButton>
                ))}
              </div>
            </FormFieldWrapper>
          )}
          {suggestions.length > 0 && (
            <FormFieldWrapper label="Recommended locations:">
              <div className="flex flex-row flex-wrap gap-3">
                {suggestions
                  .filter((item) => !location_targeting.includes(item.geo_name))
                  .map((item, index) => (
                    <TagButton
                      key={`suggested:${item.geo_name}:${index}`}
                      onClick={() => addLocationRecommended(item.geo_name)}
                    >
                      <PlusIcon className="size-5" />
                      <Paragraph as="span">{item.geo_name}</Paragraph>
                    </TagButton>
                  ))}
              </div>
            </FormFieldWrapper>
          )}
        </>
      )}
    </div>
  );
}
