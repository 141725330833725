import ContainerWithAmdBlock from "@/components/ContainerWithAmdBlock";
import AccessibleCustomers from "@/components/googleads/AccessibleCustomers";

export default function GoogleAdsAccountsPage() {
  return (
    <ContainerWithAmdBlock>
      <AccessibleCustomers />
    </ContainerWithAmdBlock>
  );
}
