import { useNavigate } from "react-router-dom";

import { ChatWizard } from "@/components/ChatWizard";
import { BudgetField, CreatingCampaignField } from "@/components/Campaigns/CreateCampaign/fields";
import { CampaignUrls } from "@/routes/urls";

export default function BudgetStep() {
  const navigate = useNavigate();

  return (
    <>
      <ChatWizard
        steps={[BudgetField, CreatingCampaignField]}
        title="5. Select Budget"
        onFinish={() => navigate(CampaignUrls.createSuccess)}
      />
    </>
  );
}
