import { Heading, Paragraph } from "@/components/ui/typography";
import { Button, Container, SectionCard, RectangleSection } from "@/components/ui";

type Plan = {
  id: number;
  price: number;
  oldPrice: number;
  campaigns?: number;
  properties: string[];
};

const BasicPlan: Plan = {
  id: 1,
  price: 29,
  oldPrice: 99,
  campaigns: 1,
  properties: [
    "Ad Creation Al",
    "Ad Performance Management",
    "Ad Intelligence Suggestions",
    "No minimum AD Spend",
    "Cancel Anytime"
  ]
};
const MiddlePlan: Plan = {
  id: 2,
  price: 49,
  oldPrice: 299,
  campaigns: 5,
  properties: [...BasicPlan.properties, "Run 5 Different AD Campaigns Concurrently"]
};
const TopPlan: Plan = {
  id: 3,
  price: 199,
  oldPrice: 499,
  properties: [...BasicPlan.properties, "Run Unlimited AD Campaigns Concurrently", "Premium Support"]
};

const plans: Plan[] = [BasicPlan, MiddlePlan, TopPlan];

export default function Plans() {
  return (
    <RectangleSection variant="v2">
      <Container id="plans" className="space-y-[50px] py-[72px]">
        <Heading size="h1" as="h2" className="text-white">
          Plans
        </Heading>
        <div className="grid grid-cols-1 gap-5 lg:grid-cols-3">
          {plans.map((plan) => (
            <SectionCard key={plan.id} className="flex flex-col gap-9 p-[25px] lg:p-[25px]">
              <div className="flex items-center justify-center gap-3">
                <Paragraph size="u2" className="!text-[18px] font-semibold line-through">
                  ${plan.oldPrice}
                </Paragraph>
                <Paragraph size="u1" className="font-semibold">
                  ${plan.price} p/m
                </Paragraph>
              </div>
              <div className="space-y-4">
                <Paragraph>{plan.campaigns ? campaignsVerbose(plan.campaigns) : "Unlimited campaigns"}</Paragraph>
                <ul className="ml-4 list-disc space-y-1 lg:space-y-4">
                  {plan.properties.map((property) => (
                    <li key={`${property}:${plan.id}`}>
                      <Paragraph>{property}</Paragraph>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mb-[40px] mt-auto flex justify-center">
                <Button rounded variant="light" size="lg" className="w-full py-3 lg:w-auto lg:min-w-[260px]">
                  Choose
                </Button>
              </div>
            </SectionCard>
          ))}
        </div>
      </Container>
    </RectangleSection>
  );
}

function campaignsVerbose(campaigns: number) {
  return `${campaigns} campaign${campaigns > 1 ? "s" : ""}`;
}
