import { PauseIcon } from "@heroicons/react/24/solid";

import type { BillingStatus, CampaignInfo } from "@/data/types";
import type { StatusOption } from "@/components/Campaigns/FilterByStatus";
import { cleanString } from "@/utils/string";
import { Table, Th, Td, Thead, Tbody, clsx } from "@/components/ui";
import { Paragraph } from "@/components/ui/typography";

export type CampaignsTableProps = {
  campaigns: CampaignInfo[];
  status: StatusOption | "";
  billingStatus: BillingStatus;
  onRowClick: (campaignId: string) => void;
};

export function CampaignsTable({ campaigns, status, billingStatus, onRowClick }: CampaignsTableProps) {
  let filteredCampaigns = campaigns;
  if (status === "All but removed") {
    filteredCampaigns = filteredCampaigns.filter(({ status }) => status === "Removed");
  }
  if (status === "All active") {
    filteredCampaigns = filteredCampaigns.filter(({ status }) => status === "Active");
  }

  return (
    <Table>
      <Thead>
        <tr>
          <Th key="campaign_name">Campaign</Th>
          <Th key="status">Status</Th>
          <Th key="budget">Budget per day</Th>
          <Th key="campaign_type">Campaign type</Th>
          <Th key="impressions">Impr.</Th>
          <Th key="interactions">Interactions</Th>
          <Th key="interaction_rate">Interaction rate</Th>
          <Th key="avg_cost">Avg. cost</Th>
          <Th key="total_cost">Cost</Th>
          <Th key="conversions">Conversions</Th>
          <Th key="cost_per_conversion">Cost/conv.</Th>
          <Th key="conversion_rate">Conv. rate</Th>
        </tr>
      </Thead>
      <Tbody>
        {filteredCampaigns.map((item) => (
          <tr
            key={item.campaign_id}
            onClick={() => onRowClick(item.campaign_id)}
            className={clsx("cursor-pointer text-center")}
          >
            <Td className="max-w-md">{item.campaign_name}</Td>
            <Td>
              <CampaignStatus billingStatus={billingStatus} status={item.status} />
            </Td>
            <Td>$ {item.campaign_budget}</Td>
            <Td>{item.campaign_type}</Td>
            <Td>{cleanString(item.impressions)}</Td>
            <Td>{cleanString(item.interactions)}</Td>
            <Td>{item.interaction_rate}%</Td>
            <Td>${item.cpc}</Td>
            <Td>${cleanString(item.cost)}</Td>
            <Td>{cleanString(item.conv)}</Td>
            <Td>${item.cost_per_conv}</Td>
            <Td>{item.conv_rate}%</Td>
          </tr>
        ))}
      </Tbody>
    </Table>
  );
}

function CampaignStatus({ billingStatus, status }: { billingStatus: BillingStatus; status: CampaignInfo["status"] }) {
  const billingApproved = billingStatus === "APPROVED";
  return (
    <div className="flex flex-row items-start gap-2">
      <div
        className={clsx(
          "mt-1 flex size-3.5 shrink-0 items-center justify-center rounded-full bg-icon-light",
          status === "Active" && "bg-green-500",
          status === "Removed" && "bg-red-500",
          !billingApproved && "bg-icon-light"
        )}
      >
        {status === "Paused" && <PauseIcon className="size-3 text-amd-dark" />}
      </div>
      <Paragraph size="p3" as="span" className="text-left">
        {billingApproved ? status : "Inactive until billing is set up"}
      </Paragraph>
    </div>
  );
}
