import { useCallback, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { TextField } from "@/components/ui/form";
import { FieldProps, FieldWrapper } from "./BaseField";
import { useData } from "@/hooks/useData";

import {
  headlinesSchema,
  descriptionsSchema,
  HEADLINES_MAX_LENGTH,
  DESC_MAX_LENGTH,
  DescriptionsFormData,
  HeadlinesFormData
} from "./SmartAddsConstants";
import AdPreviewBlock from "@/components/Campaigns/CreateCampaign/AdPreviewBlock";
import { useCampaign } from "@/hooks/useCampaign";
import { hasDifference } from "@/utils/array";
import { useEditAdCreativeMutation } from "@/data/api";
import { CardWithIcon } from "@/components/ui";

const schema = headlinesSchema.concat(descriptionsSchema);

type FormData = HeadlinesFormData & DescriptionsFormData;

export type AdPreviewFieldProps = FieldProps & {
  saveImmediately?: boolean;
};

const keys = ["headline_1", "headline_2", "headline_3", "desc_1", "desc_2"] as const;

export function AdPreviewField({ active, handleNext, saveImmediately = false, ...props }: AdPreviewFieldProps) {
  const [messageWarning, setMessageWarning] = useState("");
  const {
    headline_1,
    setHeadline_1,
    headline_2,
    setHeadline_2,
    headline_3,
    setHeadline_3,
    desc_1,
    setDesc_1,
    desc_2,
    setDesc_2
  } = useData();
  const { campaignInfo, requestAvailable, payload } = useCampaign();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    control
  } = useForm<FormData>({
    mode: "onChange",
    resolver: yupResolver(schema),
    values: {
      headline_1,
      headline_2,
      headline_3,
      desc_1,
      desc_2
    }
  });

  const [hValue1, hValue2, hValue3, dValue1, dValue2] = useWatch({
    control,
    name: keys
  });

  const [editAdCreative, { isLoading }] = useEditAdCreativeMutation();
  const saveAdCopy = useCallback(
    async (data: FormData) => {
      if (
        !saveImmediately ||
        !requestAvailable ||
        !hasDifference(
          keys.map((key) => data[key]),
          keys.map((key) => campaignInfo?.[key])
        )
      ) {
        return;
      }
      const { headline_1, headline_2, headline_3, desc_1, desc_2 } = data;
      setMessageWarning("Changing campaign ad creative... It can take a few seconds.");
      try {
        await editAdCreative({
          ...payload,
          new_headline_1: headline_1,
          new_headline_2: headline_2,
          new_headline_3: headline_3,
          new_desc_1: desc_1,
          new_desc_2: desc_2
        }).unwrap();
        setMessageWarning("");
      } catch (err) {
        setMessageWarning("Error when trying to change ad creative. Please try again.");
        throw err;
      }
    },
    [campaignInfo, editAdCreative, payload, requestAvailable, saveImmediately]
  );

  const onSubmit = useCallback(
    ({ headline_1, headline_2, headline_3, desc_1, desc_2 }: FormData) => {
      setHeadline_1(headline_1);
      setHeadline_2(headline_2);
      setHeadline_3(headline_3);
      setDesc_1(desc_1);
      setDesc_2(desc_2);
      saveAdCopy({ headline_1, headline_2, headline_3, desc_1, desc_2 })
        .then(() => {
          handleNext!();
        })
        .catch();
    },
    [handleNext, saveAdCopy, setDesc_1, setDesc_2, setHeadline_1, setHeadline_2, setHeadline_3]
  );

  return (
    <>
      {active && <AdPreviewBlock headlines={[hValue1, hValue2, hValue3]} descriptions={[dValue1, dValue2]} />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <FieldWrapper
          label="3. Here is your ad preview. You can edit content below."
          active={active}
          valid={isValid}
          {...props}
        >
          <TextField
            label="Headline 1"
            placeholder="Enter first headline for your ad..."
            {...register("headline_1")}
            error={errors.headline_1?.message}
            className="max-w-2xl"
            maxLength={HEADLINES_MAX_LENGTH}
            value={hValue1}
          />
          <TextField
            label="Headline 2"
            placeholder="Enter second headline for your ad..."
            {...register("headline_2")}
            error={errors.headline_2?.message}
            className="max-w-2xl"
            maxLength={HEADLINES_MAX_LENGTH}
            value={hValue2}
          />
          <TextField
            label="Headline 3"
            placeholder="Enter third headline for your ad..."
            {...register("headline_3")}
            error={errors.headline_3?.message}
            className="max-w-2xl"
            maxLength={HEADLINES_MAX_LENGTH}
            value={hValue3}
          />
          <TextField
            label="Description 1"
            placeholder="Enter first description for your ad..."
            {...register("desc_1")}
            error={errors.desc_1?.message}
            className="max-w-2xl"
            maxLength={DESC_MAX_LENGTH}
            value={dValue1}
          />
          <TextField
            label="Description 2"
            placeholder="Enter second description for your ad..."
            {...register("desc_2")}
            error={errors.desc_2?.message}
            className="max-w-2xl"
            maxLength={DESC_MAX_LENGTH}
            value={dValue2}
          />
        </FieldWrapper>
      </form>
      {messageWarning && (
        <CardWithIcon icon={isLoading ? "spinner" : "warning"} variant="warning">
          {messageWarning}
        </CardWithIcon>
      )}
    </>
  );
}
