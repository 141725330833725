import { useState } from "react";

import { clsx } from "@/components/ui";
import CreateCampaignLayout from "@/components/Campaigns/CreateCampaignLayout";
import GeneralInfoStep from "@/components/Campaigns/CreateCampaign/GeneralInfoStep";
import { IntroMessage } from "@/components/Campaigns/CreateCampaign/fields";

export default function CreateCampaignPage() {
  const [intro, setIntro] = useState(true);

  return (
    <CreateCampaignLayout className={clsx(intro && "flex items-center justify-center")}>
      {intro ? <IntroMessage active={intro} onClick={() => setIntro(false)} /> : <GeneralInfoStep />}
    </CreateCampaignLayout>
  );
}
