import { useCallback, useEffect, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { TextField } from "@/components/ui/form";
import { FieldProps, FieldWrapper } from "./BaseField";
import { useData } from "@/hooks/useData";
import { DESC_MAX_LENGTH, DescriptionsFormData, descriptionsSchema } from "./SmartAddsConstants";
import { useAdCopyRecommendations } from "@/hooks/useAdCopyRecommendations";

export function DescriptionsField({ active, handleNext, ...props }: FieldProps) {
  const { desc_1, setDesc_1, desc_2, setDesc_2 } = useData();
  const { descriptions } = useAdCopyRecommendations();

  const initialValues = useMemo(
    () => ({
      desc_1,
      desc_2
    }),
    [desc_1, desc_2]
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    control
  } = useForm<DescriptionsFormData>({
    mode: "onChange",
    resolver: yupResolver(descriptionsSchema),
    values: initialValues
  });
  const [descValue1, descValue2] = useWatch({ control, name: ["desc_1", "desc_2"] });

  useEffect(() => {
    if (descriptions) {
      descriptions.slice(0, 2).forEach((headline, index) => {
        const key = `desc_${index + 1}` as "desc_1" | "desc_2";
        if (!initialValues[key]) {
          setValue(key, headline);
        }
      });
    }
  }, [descriptions, initialValues, setValue]);

  const onSubmit = useCallback(
    ({ desc_1, desc_2 }: DescriptionsFormData) => {
      setDesc_1(desc_1);
      setDesc_2(desc_2);
      handleNext!();
    },
    [handleNext, setDesc_1, setDesc_2]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FieldWrapper
        label="2. Your descriptions should highlight what sets you apart from your competition. Check spelling and grammar."
        active={active}
        valid={isValid}
        {...props}
      >
        <TextField
          label="Description 1"
          placeholder="Enter first description for your ad..."
          {...register("desc_1")}
          error={errors.desc_1?.message}
          className="max-w-2xl"
          maxLength={DESC_MAX_LENGTH}
          value={descValue1}
        />
        <TextField
          label="Description 2"
          placeholder="Enter second description for your ad..."
          {...register("desc_2")}
          error={errors.desc_2?.message}
          className="max-w-2xl"
          maxLength={DESC_MAX_LENGTH}
          value={descValue2}
        />
      </FieldWrapper>
    </form>
  );
}
