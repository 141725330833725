import ContainerWithAmdBlock from "@/components/ContainerWithAmdBlock";
import { Heading, Paragraph } from "@/components/ui/typography";
import Link from "@/components/Link";
import { AuthUrls } from "@/routes/urls";

export default function PasswordResetSuccessPage() {
  return (
    <ContainerWithAmdBlock className="max-w-[460px] lg:pt-44">
      <div className="space-y-4">
        <Heading.h1>Check your email</Heading.h1>
        <Paragraph>We sent a password reset link to johndoe@gmail.com</Paragraph>
      </div>
      <Paragraph>
        Don’t receive the email?{" "}
        <Link to={AuthUrls.login}>
          <strong>Click to resend</strong>
        </Link>
      </Paragraph>
      <Paragraph>
        <Link to={AuthUrls.login}>
          Return to <strong>Login</strong>
        </Link>
      </Paragraph>
    </ContainerWithAmdBlock>
  );
}
