import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useCampaign } from "@/hooks/useCampaign";
import { useChangeCampaignNameMutation } from "@/data/api";
import { CardWithIcon } from "@/components/ui";
import { TextField } from "@/components/ui/form";
import { FieldProps, FieldWrapper } from "./BaseField";

const schema = yup
  .object({
    campaign_name: yup.string().required()
  })
  .required();

type FormData = {
  campaign_name: string;
};

export function CampaignNameField({ handleNext, ...props }: FieldProps) {
  const [messageWarning, setMessageWarning] = useState("");
  const { campaignInfo, payload } = useCampaign();
  const [changeCampaignName, { isLoading }] = useChangeCampaignNameMutation();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    values: {
      campaign_name: campaignInfo?.campaign_name
    }
  });

  const onSubmit = useCallback(
    ({ campaign_name }: FormData) => {
      if (campaign_name !== campaignInfo?.campaign_name) {
        setMessageWarning("Changing campaign name... It can take a few seconds.");
        changeCampaignName({ campaignName: campaign_name, ...payload })
          .unwrap()
          .then(() => {
            setMessageWarning("");
            handleNext!();
          })
          .catch(() => {
            setMessageWarning("Error when trying to change name");
          });
      } else {
        handleNext!();
      }
    },
    [campaignInfo?.campaign_name, changeCampaignName, handleNext, payload]
  );

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FieldWrapper
          label="Enter name of your campaign"
          description="This name will not appear in your ad."
          valid={isValid}
          {...props}
        >
          <TextField
            placeholder="Enter name of your campaign"
            {...register("campaign_name")}
            error={errors.campaign_name?.message}
            className="max-w-2xl"
          />
        </FieldWrapper>
      </form>
      {messageWarning && (
        <CardWithIcon icon={isLoading ? "spinner" : "warning"} variant="warning" className="max-w-3xl">
          {messageWarning}
        </CardWithIcon>
      )}
    </>
  );
}
