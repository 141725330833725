import { useNavigate } from "react-router-dom";
import { Heading, Paragraph } from "@/components/ui/typography";
import { Button } from "@/components/ui";
import { CampaignUrls } from "@/routes/urls";

export function NoCampaigns() {
  const navigate = useNavigate();

  return (
    <div className="mx-auto flex max-w-[593px] flex-col items-center justify-center gap-8 text-center">
      <img src="/not-found.svg" alt="nothing found" />
      <Heading.h2 className="font-semibold">Oh! It seems you do not have ads running on Google yet.</Heading.h2>
      <Paragraph size="u1">Create a campaign to start advertising on Google!</Paragraph>
      <div>
        <Button
          variant="light"
          rounded
          className="py-2 font-semibold"
          size="lg"
          onClick={() => navigate(CampaignUrls.create)}
        >
          Create Campaign
        </Button>
      </div>
    </div>
  );
}
