export type AccountInfo = {
  manager_account_linked: string;
  customer_id: string;
  account_type: "Client" | string;
  description: string;
  status: string;
  time_zone: string;
  currency: string;
  billing_status: string;
  business_name: string;
  final_url: string;
  phone_number: string;
  business_location_id: string;
};

export type BudgetType = "recommended" | "low" | "high" | "custom";

export type BudgetRecommendation = {
  high: number;
  high_min_clicks: number;
  high_max_clicks: number;
  recommended: number;
  recommended_min_clicks: number;
  recommended_max_clicks: number;
  low: number;
  low_min_clicks: number;
  low_max_clicks: number;
  currency: string;
};

export type RefreshToken = undefined | 0 | 1;

export type LookupRefreshToken = {
  customer_id: number;
  refresh_token: RefreshToken;
};

export type TokensPayload = {
  token: string;
  refreshToken: RefreshToken;
};

export type GetTokenPayload = {
  token: string;
  googleAccessCode: string;
  passThroughValue: string | null;
};

export type LinkAccountsPayload = TokensPayload & {
  customerId: number | string; //FIXME: unify customer Id type
};

export type CustomerID = string | number;

export type ConnectResponse = {
  url: string;
  passthrough_val: string;
};

export type CreateAccountPayload = {
  token: string;
  refreshToken?: RefreshToken;
  customerId?: CustomerID;
  account_name: string;
  email: string;
  currency: string;
  time_zone: string;
};

export type CreateSmartCampaignPayload = TokensPayload & {
  customerId: CustomerID;
  country_code: string;
  language_code: string;
  geo_target_names: string[];
  landing_page: string;
  display_name: string[];
  business_name: string;
  selected_budget: string;
  phone_number: string;
  business_location_id: string;
  headline_1_user: string;
  headline_2_user: string;
  headline_3_user: string;
  desc_1_user: string;
  desc_2_user: string;
};

export type GetCampaignsPayload = TokensPayload & {
  customerId: CustomerID;
  date: string;
};

export type GetBudgetRecommendationsPayload = TokensPayload & {
  customerId: CustomerID;
  country_code: string;
  language_code: string;
  geo_target_names: string[];
  landing_page: string;
  display_name: string[];
  business_name?: string;
};

export type CampaignID = string;

export type CampaignInfo = {
  campaign_id: CampaignID;
  status: "Active" | "Paused" | "Removed";
  campaign_name: string;
  campaign_budget: number;
  campaign_type: string;
  impressions: string; //FIXME
  interactions: string; //FIXME
  interaction_rate: number;
  cpc: number;
  cost: number;
  cost_avg: number;
  conv: number;
  cost_per_conv: number;
  conv_rate: number;
  keyword_themes: string[];
  geo_targets: string[];
  country_code: string;
  language_code: string;
  final_url: string;
  budget_id: string;
  business_name: string;
  ad_id: string;
  new_campaign_name: string;
  new_status: string;
  conv_value: number;
  budget_micros: number;
  new_budget_micros: number;
  new_campaign_budget: number;
  headline_1: string;
  headline_2: string;
  headline_3: string;
  desc_1: string;
  desc_2: string;
  MONDAY: string;
  MONDAY_start_hour: string;
  MONDAY_end_hour: string;
  TUESDAY: string;
  TUESDAY_start_hour: string;
  TUESDAY_end_hour: string;
  WEDNESDAY: string;
  WEDNESDAY_start_hour: string;
  WEDNESDAY_end_hour: string;
  THURSDAY: string;
  THURSDAY_start_hour: string;
  THURSDAY_end_hour: string;
  FRIDAY: string;
  FRIDAY_start_hour: string;
  FRIDAY_end_hour: string;
  SATURDAY: string;
  SATURDAY_start_hour: string;
  SATURDAY_end_hour: string;
  SUNDAY: string;
  SUNDAY_start_hour: string;
  SUNDAY_end_hour: string;
};

export type BillingStatus = "APPROVED";

export enum Currency {
  USD = "USD",
  AUD = "AUD",
  EUR = "EUR"
}

export type LocationSuggestion = {
  geo_id: string;
  geo_name: string;
};

export type GetLocationRecommendationsPayload = TokensPayload & {
  customerId: CustomerID;
  location_input: string;
  country_code: string;
  language_code: string;
};

export type AdCreative = {
  new_head_1_api: string;
  new_head_2_api: string;
  new_head_3_api: string;
  new_desc_1_api: string;
  new_desc_2_api: string;
};

export type SearchTerm = {
  search_term: string;
  search_term_clicks: number;
  search_term_cost: number;
};

export type CrudCampaignPayload = TokensPayload & {
  customerId: CustomerID;
  campaignId?: CampaignID;
};

export type StatusChange = "Active" | "Paused" | "Deleted";

export type EditAdCreativePayload = {
  new_headline_1: string;
  new_headline_2: string;
  new_headline_3: string;
  new_desc_1: string;
  new_desc_2: string;
};

export type KeywordRecommendationsPayload = CrudCampaignPayload & {
  keyword_text: string;
  country_code: string;
  language_code: string;
  final_url: string;
  business_name: string;
  geo_target_names: string[];
};

export type AdRecommendationsPayload = CrudCampaignPayload & {
  country_code: string;
  language_code: string;
  landing_page: string;
  business_name: string;
  geo_target_names: string[];
  display_name: string[];
};

export type ChangeCampaignKeywordsPayload = CrudCampaignPayload & {
  display_name: string[];
};

export type ChangeCampaignGeoTargetsPayload = CrudCampaignPayload & {
  new_geo_target_names: string[];
  country_code: string;
  language_code: string;
};

export type ChangeCampaignSchedulePayload = CrudCampaignPayload & {
  mon_start: string;
  mon_end: string;
  tue_start: string;
  tue_end: string;
  wed_start: string;
  wed_end: string;
  thu_start: string;
  thu_end: string;
  fri_start: string;
  fri_end: string;
  sat_start: string;
  sat_end: string;
  sun_start: string;
  sun_end: string;
};

export type AdRecommendations = {
  headlines: string[];
  descriptions: string[];
};
