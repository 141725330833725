import { useCallback, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useData } from "@/hooks/useData";
import { TextField } from "@/components/ui/form";
import { FieldProps, FieldWrapper } from "./BaseField";
import FormFieldWrapper from "@/components/Campaigns/Forms/FieldWrapper";
import { useChangeCampaignKeywordsMutation, useLazyGetKeywordRecommendationsQuery } from "@/data/api";
import { toTitleCase } from "@/utils/string";
import { Button, TagButton, CardWithIcon } from "@/components/ui";
import { Paragraph } from "@/components/ui/typography";
import { useCampaign } from "@/hooks/useCampaign";
import { hasDifference } from "@/utils/array";
const MAX_THEMES_QTY = 7;

const MAX_LENGTH = 30;

const schema = yup
  .object({
    keyword_theme: yup.string().required("Enter a category in the text box.").max(MAX_LENGTH)
  })
  .required();

type FormData = {
  keyword_theme: string;
};

type KeywordThemesFieldProps = FieldProps & {
  saveImmediately?: boolean;
};

export function KeywordThemesField({ handleNext, saveImmediately = false, ...props }: KeywordThemesFieldProps) {
  const [messageWarning, setMessageWarning] = useState("");
  const [selectedKeywordThemes, setSelectedKeywordThemes] = useState<string[]>([]);
  const [getKeywordRecommendations, { data: keywordRecommendations = [] }] = useLazyGetKeywordRecommendationsQuery();
  const recommendations = keywordRecommendations
    .filter((item) => !selectedKeywordThemes.includes(item))
    .filter(Boolean);

  const {
    token,
    refreshToken,
    customerId,
    country_code,
    language_code,
    keyword_themes,
    setKeyword_themes,
    geo_location,
    landing_page,
    business_name
  } = useData();
  const { payload, requestAvailable, campaignInfo } = useCampaign();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control
  } = useForm<FormData>({
    resolver: yupResolver(schema)
  });
  const keywordThemeValue = useWatch({ control, name: "keyword_theme" });

  useEffect(() => {
    if (keyword_themes) {
      try {
        setSelectedKeywordThemes(JSON.parse(JSON.stringify(keyword_themes)));
      } catch (e) {
        console.error(e);
      }
    }
  }, [keyword_themes]);

  const addSelectedKeyTheme = (item: string) => {
    if (selectedKeywordThemes.length < MAX_THEMES_QTY) {
      setSelectedKeywordThemes([...selectedKeywordThemes, item]);
    } else {
      setMessageWarning("Remove selected categories to add new ones.");
    }
  };

  const removeTheme = useCallback(
    (itemToRemove: string) => {
      setSelectedKeywordThemes(selectedKeywordThemes.filter((el) => el !== itemToRemove));
    },
    [selectedKeywordThemes]
  );

  const onSubmit = useCallback(
    ({ keyword_theme }: FormData) => {
      if (!token || !customerId || refreshToken === undefined) {
        return;
      }

      if (selectedKeywordThemes.length >= MAX_THEMES_QTY) {
        setMessageWarning("Remove one category to add this one.");
        return;
      }

      const themeInput = toTitleCase(keyword_theme);
      if (selectedKeywordThemes.includes(themeInput)) {
        setMessageWarning(`You already added ${themeInput}.`);
        return;
      }

      setMessageWarning("");
      setSelectedKeywordThemes([...selectedKeywordThemes, themeInput]);
      getKeywordRecommendations({
        token,
        refreshToken,
        keyword_text: themeInput,
        country_code,
        language_code,
        customerId,
        final_url: landing_page,
        business_name,
        geo_target_names: geo_location
      });
    },
    [
      business_name,
      country_code,
      customerId,
      geo_location,
      getKeywordRecommendations,
      landing_page,
      language_code,
      refreshToken,
      selectedKeywordThemes,
      token
    ]
  );

  const [changeCampaignKeywords, { isLoading }] = useChangeCampaignKeywordsMutation();
  const saveKeywords = useCallback(
    async (keywordThemes: string[]) => {
      if (!saveImmediately || !requestAvailable || !hasDifference(keywordThemes, campaignInfo?.keyword_themes || [])) {
        return;
      }

      setMessageWarning("Changing the categories of keywords... It can take a few seconds.");
      try {
        await changeCampaignKeywords({
          ...payload,
          display_name: keywordThemes
        }).unwrap();
        setMessageWarning("");
      } catch (err) {
        setMessageWarning("Error when trying to change categories of keywords. Please try again.");
        throw err;
      }
    },
    [campaignInfo?.keyword_themes, changeCampaignKeywords, payload, requestAvailable, saveImmediately]
  );

  const goNext = useCallback(() => {
    if (selectedKeywordThemes.length > 0 || keyword_themes) {
      setKeyword_themes(selectedKeywordThemes);
      saveKeywords(selectedKeywordThemes)
        .then(() => {
          handleNext!();
        })
        .catch();
    } else {
      setMessageWarning("Please select at least one category of keywords to go to the next set.");
    }
  }, [handleNext, keyword_themes, saveKeywords, selectedKeywordThemes, setKeyword_themes]);

  return (
    <div className="space-y-[46px]">
      {messageWarning && (
        <CardWithIcon icon={isLoading ? "spinner" : "warning"} variant="warning">
          {messageWarning}
        </CardWithIcon>
      )}
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-[46px]">
        <FieldWrapper
          label="Select categories of keywords"
          description={`Add up to ${MAX_THEMES_QTY} categories of keywords, and don’t use punctuation marks, phone numbers, or webpage address (URL).`}
          valid={selectedKeywordThemes.length > 0}
          handleNext={goNext}
          nextSubmitButton={false}
          {...props}
        >
          <TextField
            {...register("keyword_theme")}
            error={errors.keyword_theme?.message}
            className="max-w-2xl"
            value={keywordThemeValue}
            maxLength={MAX_LENGTH}
          />
        </FieldWrapper>
        {props.active && (
          <>
            <CardWithIcon icon="warning" variant="warning">
              If there are certain words or phrases your customers use, consider including them as a category of
              keywords
            </CardWithIcon>
            <div className="space-y-4">
              <Button variant="light" type="submit" rounded size="lg">
                Add & Recommend
              </Button>
              <Paragraph size="p2">Recommendations come from your category of keywords.</Paragraph>
            </div>
          </>
        )}
      </form>
      {props.active && (
        <>
          {selectedKeywordThemes.length > 0 && (
            <FormFieldWrapper
              className="space-y-[30px]"
              label="Selected category keywords:"
              description={
                selectedKeywordThemes.length === MAX_THEMES_QTY
                  ? `${MAX_THEMES_QTY} categories selected. If you want to add more, create a new campaign or delete selected ones.`
                  : undefined
              }
            >
              <div className="flex flex-row flex-wrap gap-3">
                {selectedKeywordThemes.map((item, index) => (
                  <TagButton
                    key={`selected:${item}:${index}`}
                    onClick={() => removeTheme(item)}
                    variant="light"
                    rounded
                  >
                    <Paragraph as="span">{item}</Paragraph>
                    <XMarkIcon className="size-5" />
                  </TagButton>
                ))}
              </div>
            </FormFieldWrapper>
          )}
          {recommendations.length > 0 && (
            <FormFieldWrapper
              className="space-y-[30px]"
              label="Recommended category keywords:"
              description={`${recommendations.length} recommendations for your category.`}
            >
              <div className="flex flex-row flex-wrap gap-3">
                {recommendations.map((item, index) => (
                  <TagButton key={`suggested:${item}:${index}`} onClick={() => addSelectedKeyTheme(item)}>
                    <PlusIcon className="size-5" />
                    <Paragraph as="span">{item}</Paragraph>
                  </TagButton>
                ))}
              </div>
            </FormFieldWrapper>
          )}
        </>
      )}
    </div>
  );
}
