export const AuthUrls = {
  login: "/auth/login",
  logout: "/auth/logout",
  signup: "/auth/signup",
  resetPassword: "/auth/reset-password",
  resetPasswordSuccess: "/auth/reset-password-success",
  changePassword: "/auth/change-password",
  changePasswordSuccess: "/auth/change-password-success"
};

export const CampaignUrls = {
  list: "/campaigns",
  create: "/create-campaign",
  edit: "/edit-campaign",
  writeSmartAd: "/write-smart-ad",
  keywordThemes: "/keyword-themes",
  geoLocation: "/geo-location",
  budget: "/budget",
  createSuccess: "/create-success"
};

export const PagesUrls = {
  learnMore: "/learn-more",
  getStarted: "/get-started",
  about: "/about"
};

export const GoogleAdsUrls = {
  googleads: "/googleads",
  accounts: "/googleads/accounts",
  createAccount: "/googleads/create-account"
};
