import React, { PropsWithChildren } from "react";
import { Description, Field, FieldProps } from "@headlessui/react";

import ErrorText from "./error-text";
import { Label } from "./label";
import { clsx } from "../clsx";

export type FieldLayoutProps = PropsWithChildren & {
  label?: string;
  required?: boolean;
  error?: string;
  helpText?: React.ReactNode;
  disabled?: FieldProps["disabled"];
};

export function FieldLayout({ label, error, helpText, children, disabled, required = false }: FieldLayoutProps) {
  return (
    <Field disabled={disabled} className="space-y-2">
      {label && (
        <Label>
          {label}
          {required ? "*" : ""}
        </Label>
      )}
      {children}
      {helpText && (
        <Description className="text-sm text-amd-light" as="div">
          {helpText}
        </Description>
      )}
      {error && <ErrorText>{error}</ErrorText>}
    </Field>
  );
}

export const CommonControlColorStyles = clsx("bg-white/8 rounded-lg border border-white/20");

export const CommonControlStyles = clsx(
  CommonControlColorStyles,
  "block w-full px-5 py-2",
  "text-sm text-white placeholder:text-amd-light lg:text-base",
  "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
);
