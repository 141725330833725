import ContainerWithAmdBlock from "@/components/ContainerWithAmdBlock";
import { Heading } from "@/components/ui/typography";

export default function AboutPage() {
  return (
    <ContainerWithAmdBlock className="py-10 text-white">
      <Heading>About Admandan</Heading>
    </ContainerWithAmdBlock>
  );
}
