import { useNavigate } from "react-router-dom";

import { ChatWizard } from "@/components/ChatWizard";
import { LocationField } from "@/components/Campaigns/CreateCampaign/fields";
import { CampaignUrls } from "@/routes/urls";

export default function LocationStep() {
  const navigate = useNavigate();

  return (
    <ChatWizard
      steps={[LocationField]}
      title="2. Select Location"
      onFinish={() => navigate(CampaignUrls.keywordThemes)}
    />
  );
}
