import { Heading, Paragraph } from "@/components/ui/typography";
import { SectionCard } from "@/components/ui";

export default function ManagingAds() {
  return (
    <SectionCard className="space-y-6 lg:space-y-9" opacity={false}>
      <Heading.h2>“Managing ads sucks!”</Heading.h2>
      <div className="space-y-4">
        <Paragraph size="u2">
          Ads for small business is time consuming, dark magic and expensive. Admin Dan is your dedicated man in
          marketing, ready to take the wheel and steer your ads to success.
        </Paragraph>
        <Paragraph size="u2">Intelligent ad creation</Paragraph>
        <ul className="ml-4 list-outside list-disc space-y-4">
          <li>
            <Paragraph as="span" size="u2">
              Full budget control & cost transparency always
            </Paragraph>
          </li>
          <li>
            <Paragraph as="span" size="u2">
              No commitment. Pause, decease, increase, delete in a click. No worries
            </Paragraph>
          </li>
          <li>
            <Paragraph as="span" size="u2">
              No guesswork, Admin Dan is powered by intelligent Ad Al that gives you the unfair advantage
            </Paragraph>
          </li>
        </ul>
      </div>
    </SectionCard>
  );
}
