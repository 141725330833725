import { useCallback, useEffect, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { TextField } from "@/components/ui/form";
import { FieldProps, FieldWrapper } from "./BaseField";
import { useData } from "@/hooks/useData";
import { HEADLINES_MAX_LENGTH, HeadlinesFormData, headlinesSchema } from "./SmartAddsConstants";
import { useAdCopyRecommendations } from "@/hooks/useAdCopyRecommendations";

export function HeadlinesField({ active, handleNext, ...props }: FieldProps) {
  const { headline_1, setHeadline_1, headline_2, setHeadline_2, headline_3, setHeadline_3 } = useData();

  const { headlines } = useAdCopyRecommendations();

  const initialValues = useMemo(
    () => ({
      headline_1,
      headline_2,
      headline_3
    }),
    [headline_1, headline_2, headline_3]
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    control
  } = useForm<HeadlinesFormData>({
    mode: "onChange",
    resolver: yupResolver(headlinesSchema),
    values: initialValues
  });

  const [hValue1, hValue2, hValue3] = useWatch({ control, name: ["headline_1", "headline_2", "headline_3"] });

  useEffect(() => {
    if (headlines) {
      headlines.slice(0, 3).forEach((headline, index) => {
        const key = `headline_${index + 1}` as "headline_1" | "headline_2" | "headline_3";
        if (!initialValues[key]) {
          setValue(key, headline);
        }
      });
    }
  }, [headlines, initialValues, setValue]);

  const onSubmit = useCallback(
    ({ headline_1, headline_2, headline_3 }: HeadlinesFormData) => {
      setHeadline_1(headline_1);
      setHeadline_2(headline_2);
      setHeadline_3(headline_3);
      handleNext!();
    },
    [handleNext, setHeadline_1, setHeadline_2, setHeadline_3]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FieldWrapper
        label="1. Your headlines are what your customers will focus on. Keep them simple, clear, and related to what they are searching for."
        active={active}
        valid={isValid}
        {...props}
      >
        <TextField
          label="Headline 1"
          placeholder="Enter first headline for your ad..."
          {...register("headline_1")}
          error={errors.headline_1?.message}
          className="max-w-2xl"
          maxLength={HEADLINES_MAX_LENGTH}
          value={hValue1}
        />
        <TextField
          label="Headline 2"
          placeholder="Enter second headline for your ad..."
          {...register("headline_2")}
          error={errors.headline_2?.message}
          className="max-w-2xl"
          maxLength={HEADLINES_MAX_LENGTH}
          value={hValue2}
        />
        <TextField
          label="Headline 3"
          placeholder="Enter third headline for your ad..."
          {...register("headline_3")}
          error={errors.headline_3?.message}
          className="max-w-2xl"
          maxLength={HEADLINES_MAX_LENGTH}
          value={hValue3}
        />
      </FieldWrapper>
    </form>
  );
}
