import { Container } from "@/components/ui";
import Link, { LinkProps } from "@/components/Link";
import { AuthUrls, CampaignUrls, PagesUrls } from "@/routes/urls";

function FooterLink(props: LinkProps) {
  return <Link {...props} className="text-white hover:underline" />;
}

export default function Footer() {
  return (
    <Container fluid className="border-t border-t-white/20 py-2.5">
      <Container>
        <ul className="flex flex-col items-center justify-evenly gap-y-4 lg:flex-row">
          <li>
            <FooterLink to={AuthUrls.login}>Log In/ Sign up</FooterLink>
          </li>
          <li>
            <FooterLink to={PagesUrls.learnMore}>Why use Google Ads?</FooterLink>
          </li>
          <li>
            <FooterLink to={CampaignUrls.list}>Google Ads</FooterLink>
          </li>
          <li>
            <FooterLink to={"/about"}>About</FooterLink>
          </li>
        </ul>
      </Container>
      <div className="mb-2.5 mt-9 text-center text-white">
        Copyright @ {new Date().getFullYear()} AMD. All rights reserved
      </div>
    </Container>
  );
}
