import { useEffect, useMemo, useState } from "react";

import { useData } from "@/hooks/useData";
import { useCreateSmartCampaignMutation } from "@/data/api";
import { CardWithIcon } from "@/components/ui";
import { FieldProps, FieldWrapper } from "./BaseField";

const optionalFields = ["business_location_id"];

export function CreatingCampaignField({ active, handleNext, ...props }: FieldProps) {
  const [messageWarning, setMessageWarning] = useState("");
  const {
    token,
    refreshToken,
    country_code,
    language_code,
    customerId,
    geo_location,
    landing_page,
    keyword_themes,
    business_name,
    phone_number,
    business_location_id,
    headline_1,
    headline_2,
    headline_3,
    desc_1,
    desc_2,
    budget,
    cleanCookies
  } = useData();

  const payload = useMemo(
    () => ({
      token: token!,
      refreshToken,
      customerId: customerId!,
      country_code,
      language_code,
      geo_target_names: geo_location,
      landing_page,
      display_name: keyword_themes,
      selected_budget: `${budget}`,
      phone_number,
      business_name,
      business_location_id,
      headline_1_user: headline_1,
      headline_2_user: headline_2,
      headline_3_user: headline_3,
      desc_1_user: desc_1,
      desc_2_user: desc_2
    }),
    [
      budget,
      business_location_id,
      business_name,
      country_code,
      customerId,
      desc_1,
      desc_2,
      geo_location,
      headline_1,
      headline_2,
      headline_3,
      keyword_themes,
      landing_page,
      language_code,
      phone_number,
      refreshToken,
      token
    ]
  );

  const isValid = useMemo(
    () => Object.entries(payload).every(([k, v]) => v !== undefined || optionalFields.includes(k)),
    [payload]
  );

  const [createCampaign, { isLoading, error }] = useCreateSmartCampaignMutation();
  const showButton = !isValid || !!error;

  useEffect(() => {
    if (isValid && payload) {
      createCampaign(payload)
        .unwrap()
        .then(() => {
          cleanCookies();
          handleNext!();
        })
        .catch((err) => {
          setMessageWarning(err.data[0] || "Something went wrong");
        });
    }
  }, [createCampaign, handleNext, isValid, payload]);

  return (
    <>
      <FieldWrapper
        label="Creating a campaign ... It can take a few seconds"
        active={active}
        isLoading={isLoading}
        showButtons={showButton}
        {...props}
      />
      {messageWarning && (
        <CardWithIcon icon="warning" variant="warning" className="max-w-3xl">
          {messageWarning}
        </CardWithIcon>
      )}
    </>
  );
}
