import { Label as HeadlessLabel, LabelProps as HeadlessLabelProps } from "@headlessui/react";

import { clsx } from "../clsx";

export type LabelProps = HeadlessLabelProps & {
  className?: string;
};

export const LabelStyles = "text-[18px] font-semibold text-white";

export function Label({ className, ...props }: LabelProps) {
  return <HeadlessLabel className={clsx(LabelStyles, className)} {...props} />;
}
