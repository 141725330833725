import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useData } from "@/hooks/useData";
import { TextField } from "@/components/ui/form";
import { FieldProps, FieldWrapper } from "./BaseField";
import { useAccountInfo } from "@/hooks/useAccountInfo";

const schema = yup
  .object({
    business_name: yup.string().required()
  })
  .required();

type FormData = {
  business_name: string;
};

export function BusinessNameField({ handleNext, ...props }: FieldProps) {
  const { business_name, setBusiness_name } = useData();
  const { businessName } = useAccountInfo();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    values: {
      business_name
    }
  });

  useEffect(() => {
    if (businessName) {
      setValue("business_name", businessName);
    }
  }, [businessName, setValue]);

  const onSubmit = useCallback(
    ({ business_name }: FormData) => {
      setBusiness_name(business_name);
      handleNext!();
    },
    [handleNext, setBusiness_name]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FieldWrapper
        label="2. Enter name of your business"
        description="This helps Google show your ad when people search for your business by name."
        valid={isValid}
        {...props}
      >
        <TextField
          placeholder="Enter name of your business"
          {...register("business_name")}
          error={errors.business_name?.message}
          className="max-w-2xl"
        />
      </FieldWrapper>
    </form>
  );
}
