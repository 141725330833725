import { useCallback, useEffect, useMemo } from "react";
import { useCookies } from "react-cookie";
import { CookieSetOptions } from "universal-cookie";

import { capitalize } from "@/utils/string";
import { useAppSelector } from "@/data/hooks";
import {
  selectCustomerId,
  selectIsAuthenticated,
  selectRefreshToken,
  selectToken,
  setCustomerId as setCustomerIdAction,
  setRefreshToken as setRefreshTokenAction,
  setToken as setTokenAction
} from "@/data/ui";
import { useDispatch } from "react-redux";
import { CustomerID, RefreshToken } from "@/data/types";
import { useLookupRefreshTokenQuery } from "@/data/api";

const DataKeys = [
  "country_code",
  "US", // FIXME:
  "business_name",
  "business_location_id",
  "landing_page",
  "phone_number",
  "language_code",
  "headline_1",
  "headline_2",
  "headline_3",
  "desc_1",
  "desc_2",
  "keyword_themes",
  "geo_location",
  "campaignId",
  "successMessage",
  "budget"
] as const;

type DataKey = (typeof DataKeys)[number];

type Capitalize<S extends string> = S extends `${infer T}${infer U}` ? `${Uppercase<T>}${U}` : S;

type Factory<T extends DataKey> = {
  [K in T | `set${Capitalize<T>}` | `remove${Capitalize<T>}`]: K extends T ? string : (value?: string) => void;
};

export function useData() {
  const [cookies, setCookie, removeCookie] = useCookies(DataKeys as unknown as string[]);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  const factorySetCookie = useCallback(
    (key: string) => (value: string, options?: CookieSetOptions) => {
      setCookie(key, value, options);
    },
    [setCookie]
  );

  const factoryRemoveCookie = useCallback((key: string) => () => removeCookie(key), [removeCookie]);

  const factory = useCallback(
    <T extends DataKey>(key: T): Factory<T> =>
      ({
        [key]: cookies[key],
        [`set${capitalize(key)}`]: factorySetCookie(key),
        [`remove${capitalize(key)}`]: factoryRemoveCookie(key)
      }) as Factory<T>,
    [cookies, factoryRemoveCookie, factorySetCookie]
  );

  const dispatch = useDispatch();
  const token = useAppSelector(selectToken);
  const setToken = useCallback(
    (token: string) => {
      dispatch(setTokenAction(token));
    },
    [dispatch]
  );

  const refreshToken = useAppSelector(selectRefreshToken);
  const setRefreshToken = useCallback(
    (value: RefreshToken) => {
      dispatch(setRefreshTokenAction(value));
    },
    [dispatch]
  );

  const customerId = useAppSelector(selectCustomerId);
  const setCustomerId = useCallback(
    (id: CustomerID) => {
      dispatch(setCustomerIdAction(id));
    },
    [dispatch]
  );

  const { data: { refresh_token, customer_id } = {} } = useLookupRefreshTokenQuery({ token: token! }, { skip: !token });

  useEffect(() => {
    if (refresh_token !== undefined) {
      setRefreshToken(parseInt(`${refresh_token}`, 10) as RefreshToken);
    }
  }, [refresh_token, setRefreshToken]);

  useEffect(() => {
    if (customer_id !== undefined) {
      setCustomerId(customer_id);
    }
  }, [customer_id, setCustomerId]);

  const geoLocationField = useMemo(() => {
    const { geo_location, setGeo_location, ...rest } = factory("geo_location");
    return {
      ...rest,
      geo_location: geo_location as unknown as string[],
      setGeo_location: (value: string[]) => {
        setGeo_location(JSON.stringify(value || []));
      }
    };
  }, [factory]);

  const keywordThemesField = useMemo(() => {
    const { keyword_themes, setKeyword_themes, ...rest } = factory("keyword_themes");
    return {
      ...rest,
      keyword_themes: keyword_themes as unknown as string[],
      setKeyword_themes: (value: string[]) => {
        setKeyword_themes(JSON.stringify(value || []));
      }
    };
  }, [factory]);

  const commonPayload = useMemo(
    () => ({
      token: token!,
      refreshToken: refreshToken!,
      customerId: customerId!
    }),
    [customerId, refreshToken, token]
  );

  return useMemo(
    () => ({
      isAuthenticated,
      logout: () => dispatch(setTokenAction(undefined)),
      token,
      setToken,
      removeToken: () => dispatch(setTokenAction(undefined)),
      refreshToken,
      setRefreshToken,
      removeRefreshToken: () => dispatch(setRefreshTokenAction(undefined)),
      customerId,
      setCustomerId,
      removeCustomerId: () => dispatch(setCustomerIdAction(undefined)),
      ...factory("country_code"),
      ...factory("language_code"),
      ...factory("landing_page"),
      ...geoLocationField,
      ...keywordThemesField,
      ...factory("phone_number"),
      ...factory("business_name"),
      ...factory("business_location_id"),
      ...factory("headline_1"),
      ...factory("headline_2"),
      ...factory("headline_3"),
      ...factory("desc_1"),
      ...factory("desc_2"),
      ...factory("successMessage"),
      ...factory("campaignId"),
      ...factory("budget"),
      commonPayload,
      cleanCookies: () => {
        DataKeys.forEach((key) => {
          removeCookie(key);
        });
      }
    }),
    [
      commonPayload,
      customerId,
      dispatch,
      factory,
      geoLocationField,
      isAuthenticated,
      keywordThemesField,
      refreshToken,
      removeCookie,
      setCustomerId,
      setRefreshToken,
      setToken,
      token
    ]
  );
}
