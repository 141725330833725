import { Button } from "@/components/ui";
import Link from "@/components/Link";
import { useData } from "@/hooks/useData";
import NavLink from "./NavLink";
import { AuthUrls } from "@/routes/urls";

export default function AuthButton() {
  const { token } = useData();
  const to = token ? AuthUrls.logout : AuthUrls.login;
  const label = token ? "Logout" : "Sign Up";

  return (
    <>
      <Link to={to} className="hidden lg:block">
        <Button variant="light">{label}</Button>
      </Link>
      <NavLink to={to} className="lg:hidden">
        {label}
      </NavLink>
    </>
  );
}
