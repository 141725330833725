import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useData } from "@/hooks/useData";
import { Heading, Paragraph } from "@/components/ui/typography";
import { Button, Card, Container } from "@/components/ui";
import { AuthUrls, GoogleAdsUrls } from "@/routes/urls";

export default function LearnMore() {
  const navigate = useNavigate();
  const { token, refreshToken } = useData();

  const [showCases, setShowCases] = useState(false);

  const goStart = () => {
    if (refreshToken) {
      navigate(GoogleAdsUrls.accounts);
    } else if (token) {
      navigate(GoogleAdsUrls.googleads);
    } else {
      navigate(AuthUrls.login);
    }
  };

  return (
    <Container className="space-y-6">
      <Heading className="text-left">Why use Google Ads?</Heading>
      <Paragraph>
        Google Ads allows you to take advantage of the benefits of online advertising: show your ads to the{" "}
        <strong>right people, in the right place, and at the right time.</strong>
      </Paragraph>
      <Paragraph>
        When you sign up for a Smart campaign, you’ll write an ad that describes your business. You’ll also choose which
        keyword themes you want to target your ad and set a budget. Your ad will automatically show to potential
        customers across Google Search, Google Maps, YouTube, Gmail, and Google partner websites.
      </Paragraph>

      <Button variant="dark" onClick={() => setShowCases(!showCases)}>
        See key benefits
        <i className="fas fa-chevron-down fa-fw" />
      </Button>
      {showCases && (
        <Card className="mt-5 space-y-3">
          <Paragraph>
            1. Create an online ad <strong>quickly and easily</strong>.
          </Paragraph>
          <Paragraph>2. Pay only when people click your ad.</Paragraph>{" "}
          <Paragraph>
            3. <strong>Attract more customers</strong> to your website or Google Maps listing.
          </Paragraph>
          <Paragraph>
            4. Minimal ongoing management necessary. <strong>Google Ads runs your ads for you</strong>.
          </Paragraph>
          <Paragraph>
            5. Reach customers on desktop computers and mobile devices (such as mobile phones and tablets).
          </Paragraph>
          <Paragraph>6. Review the effectiveness of your ads in your dashboard.</Paragraph>
        </Card>
      )}

      <div className="mt-4 flex gap-5">
        <Button variant="light" onClick={goStart} rounded>
          START
        </Button>
        <Button onClick={() => navigate("/")} rounded>
          HOME
        </Button>
      </div>
    </Container>
  );
}
