import React, { JSX, PropsWithChildren } from "react";
import { clsx } from "./clsx";

type ParagraphWeight = "light" | "normal" | "semi" | "bold" | "black";

type ParagraphSize = "p1" | "p2" | "p3" | "u1" | "u2";

type ParagraphComponentProps = PropsWithChildren & {
  as?: "p" | "span" | "div" | "small";
  className?: string;
  weight?: ParagraphWeight;
  size?: ParagraphSize;
};

const ParagraphWeightMap: Record<ParagraphWeight, string> = {
  light: "font-light",
  normal: "font-normal",
  semi: "font-semibold",
  bold: "font-bold",
  black: "font-black"
};

const ParagraphSizesMap: Record<ParagraphSize, string> = {
  p1: "text-base",
  p2: "text-sm",
  p3: "text-xs",
  u1: "text-[20px]",
  u2: "text-base"
};

export const Paragraph: React.FC<ParagraphComponentProps> = ({
  as: Component = "p",
  children,
  className = "",
  weight = "normal",
  size = "p1"
}) => {
  return (
    <Component className={clsx(ParagraphWeightMap[weight], ParagraphSizesMap[size], className)}>{children}</Component>
  );
};

type HeadingSize = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
type HeadingComponentProps = PropsWithChildren & {
  id?: string;
  className?: string;
  size?: HeadingSize;
  as?: "div" | HeadingSize;
};

const SizeMap: Record<HeadingSize, string> = {
  h1: "text-2xl lg:text-4xl font-din",
  h2: "text-lg lg:text-2xl font-poppins",
  h3: "text-xl",
  h4: "text-lg",
  h5: "text-base",
  h6: "text-sm"
};

export const Heading = ({ id, children, className, size: Tag = "h1", as }: HeadingComponentProps): JSX.Element => {
  const Component = as || Tag;
  return (
    <Component id={id} className={clsx(SizeMap[Tag], className)}>
      {children}
    </Component>
  );
};

Heading.h1 = (props: Omit<HeadingComponentProps, "size">) => <Heading {...props} size="h1" />;
Heading.h2 = (props: Omit<HeadingComponentProps, "size">) => <Heading {...props} size="h2" />;
Heading.h3 = (props: Omit<HeadingComponentProps, "size">) => <Heading {...props} size="h3" />;
Heading.h4 = (props: Omit<HeadingComponentProps, "size">) => <Heading {...props} size="h4" />;
Heading.h5 = (props: Omit<HeadingComponentProps, "size">) => <Heading {...props} size="h5" />;
Heading.h6 = (props: Omit<HeadingComponentProps, "size">) => <Heading {...props} size="h6" />;
