import { createBrowserRouter } from "react-router-dom";

// new components
import Layout from "@/routes/layout";
import ErrorPage from "@/routes/error-page";
// import Components from "@/routes/components";
import HomePage from "@/routes/home";
import LogoutPage from "@/routes/auth/logout";
import LoginPage from "@/routes/auth/login";
import SignUpPage from "@/routes/auth/signup";
import RequireAuth from "@/components/auth/RequireAuth";
import PasswordResetPage from "@/routes/auth/password-reset";

import GetStarted from "@/routes/get-started";
import LearnMorePage from "@/routes/learn-more";
import PasswordResetSuccessPage from "@/routes/auth/password-reset-success";
import ChangePasswordPage from "@/routes/auth/change-password";
import ChangePasswordSuccessPage from "@/routes/auth/change-password-success";
import GoogleAdsPage from "@/routes/googleads";
import GoogleAdsAccountsPage from "@/routes/googleads/accounts";
import CreateGoogleAdsAccountPage from "@/routes/googleads/create-account";
import CampaignsPage from "@/routes/campaigns";
import CreateCampaignPage from "@/routes/campaigns/create-campaign";
import LocationPage from "@/routes/campaigns/location";
import KeywordThemesPage from "@/routes/campaigns/keyword-themes";
import WriteSmartAdPage from "@/routes/campaigns/write-smart-ad";
import BudgetPage from "@/routes/campaigns/budget";
import CreateCampaignSuccessPage from "@/routes/campaigns/create-success";
import { CampaignUrls } from "@/routes/urls";
import EditCampaignPage from "@/routes/campaigns/edit-campaign";
import AboutPage from "@/routes/about";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <HomePage /> },
      {
        path: "/auth",
        children: [
          { path: "login", element: <LoginPage /> },
          { path: "signup", element: <SignUpPage /> },
          { path: "logout", element: <LogoutPage /> },
          { path: "reset-password", element: <PasswordResetPage /> },
          { path: "reset-password-success", element: <PasswordResetSuccessPage /> },
          { path: "change-password/:hash", element: <ChangePasswordPage /> },
          { path: "change-password-success", element: <ChangePasswordSuccessPage /> }
        ]
      },
      {
        path: "/googleads",
        children: [
          {
            index: true,
            element: (
              <RequireAuth>
                <GoogleAdsPage />
              </RequireAuth>
            )
          },
          {
            path: "accounts",
            element: (
              <RequireAuth>
                <GoogleAdsAccountsPage />
              </RequireAuth>
            )
          },
          {
            path: "create-account",
            element: (
              <RequireAuth>
                <CreateGoogleAdsAccountPage />
              </RequireAuth>
            )
          }
        ]
      },

      { path: "/get-started", element: <GetStarted /> },
      { path: "/learn-more", element: <LearnMorePage /> },
      { path: "/about", element: <AboutPage /> },
      // { path: "/components", element: <Components /> },
      {
        path: CampaignUrls.list,
        element: (
          <RequireAuth>
            <CampaignsPage />
          </RequireAuth>
        )
      },
      {
        path: CampaignUrls.create,
        element: (
          <RequireAuth>
            <CreateCampaignPage />
          </RequireAuth>
        )
      },
      {
        path: CampaignUrls.edit,
        element: (
          <RequireAuth>
            <EditCampaignPage />
          </RequireAuth>
        )
      },
      {
        path: CampaignUrls.geoLocation,
        element: (
          <RequireAuth>
            <LocationPage />
          </RequireAuth>
        )
      },
      {
        path: CampaignUrls.keywordThemes,
        element: (
          <RequireAuth>
            <KeywordThemesPage />
          </RequireAuth>
        )
      },
      {
        path: CampaignUrls.writeSmartAd,
        element: (
          <RequireAuth>
            <WriteSmartAdPage />
          </RequireAuth>
        )
      },
      {
        path: CampaignUrls.budget,
        element: (
          <RequireAuth>
            <BudgetPage />
          </RequireAuth>
        )
      },
      {
        path: CampaignUrls.createSuccess,
        element: (
          <RequireAuth>
            <CreateCampaignSuccessPage />
          </RequireAuth>
        )
      }
    ]
  }
]);
