import ContainerWithAmdBlock from "@/components/ContainerWithAmdBlock";
import { Heading, Paragraph } from "@/components/ui/typography";

import { Divider, GoogleButton } from "@/components/ui";
import Link from "@/components/Link";
import SignUpForm from "@/components/auth/SignUpForm";
import { AuthUrls } from "@/routes/urls";

export default function SignUpPage() {
  return (
    <ContainerWithAmdBlock className="max-w-[460px]">
      <Heading.h1>Create your account</Heading.h1>
      <SignUpForm />
      <Divider>
        <Paragraph size="p2">or continue</Paragraph>
      </Divider>
      <GoogleButton className="w-full justify-center py-3">Login With Google</GoogleButton>
      <Paragraph className="text-center">
        If you have an account, Please{" "}
        <Link to={AuthUrls.login}>
          <strong>Login here</strong>
        </Link>
      </Paragraph>
    </ContainerWithAmdBlock>
  );
}
