import React from "react";
import { Button as BaseButton, ButtonProps as BaseButtonProps } from "@headlessui/react";

import { clsx } from "./clsx";

export type ButtonVariant = "light" | "dark" | "black" | "outline";

const ButtonColorMap: Record<ButtonVariant, string> = {
  dark: "bg-white/8 border border-white/20 text-white hover:bg-white/10 focus:bg-white/10 active:bg-white/20",
  light: "bg-white border border-white text-amd-dark hover:bg-white/90 focus:bg-white/90 active:bg-white/90",
  black: "border border-white/20 bg-amd-bg-darker hover:bg-amd-bg-darker/80 text-white",
  outline: "border border-white/20 text-white"
};

export type SizeVariant = "md" | "lg";

const ButtonSizeMap: Record<SizeVariant, string> = {
  md: "text-base",
  lg: "text-xl font-semibold"
};

export type ButtonProps = BaseButtonProps & {
  variant?: ButtonVariant;
  size?: SizeVariant;
  rounded?: boolean;
};

export const Button: React.FC<ButtonProps> = ({
  variant = "dark",
  size = "md",
  rounded = false,
  className,
  ...props
}) => {
  return (
    <BaseButton
      className={clsx(
        "rounded-xl px-10 py-2 lg:px-10 lg:py-2",
        rounded && "rounded-full",
        ButtonColorMap[variant],
        ButtonSizeMap[size],
        "disabled:pointer-events-none disabled:select-none disabled:opacity-60",
        className
      )}
      {...props}
    />
  );
};
