import { useState } from "react";

import { Button, CardWithIcon, Container, Spinner } from "@/components/ui";
import { Heading, Paragraph } from "@/components/ui/typography";
import { useData } from "@/hooks/useData";

import { useGetBillingQuery, useGetCampaignsQuery } from "@/data/api";
import { NoCampaigns, CampaignsTable, FilterByStatus, StatusOption } from "@/components/Campaigns";
import FilterByDate, { DateFilterOption } from "@/components/FilterByDate";
import { useNavigate } from "react-router-dom";
import { CampaignUrls } from "@/routes/urls";

export default function CampaignsPage() {
  const navigate = useNavigate();
  const [date, setDate] = useState<DateFilterOption | "">(DateFilterOption.ALL_TIME);
  const [status, setStatus] = useState<StatusOption | "">("");
  const { token, refreshToken, customerId, setCampaignId } = useData();

  const requestNotAllowed = !token || refreshToken === undefined || !customerId || !date;
  const { data: campaignInfo, isLoading: campaignsLoading } = useGetCampaignsQuery(
    {
      token: token!,
      refreshToken: refreshToken!,
      customerId: customerId!,
      date
    },
    { skip: requestNotAllowed }
  );

  const { data: { status: billingStatus = "APPROVED" } = {}, isLoading: billingLoading } = useGetBillingQuery(
    {
      token: token!,
      refreshToken: refreshToken!,
      customerId: customerId!,
      date: "using same model of reporting"
    },
    { skip: requestNotAllowed || !campaignInfo?.length }
  );

  const isLoading = campaignsLoading || billingLoading;
  return (
    <Container className="space-y-6 py-9 text-white">
      <Heading className="flex items-center gap-2">Campaigns Performance {isLoading && <Spinner />}</Heading>
      {campaignInfo?.length === 0 && <NoCampaigns />}
      {campaignInfo && campaignInfo?.length > 0 && (
        <>
          {billingStatus !== "APPROVED" && (
            <CardWithIcon icon="warning-light" className="items-start">
              <Paragraph as="div" size="p2">
                Your account cannot show ads. To start running your ads,{" "}
                <a
                  href={`https://ads.google.com/aw/billing/settings?ecid=${customerId}`}
                  className="alert-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <strong>CLICK HERE</strong>
                </a>{" "}
                to enter your billing information. This link will open a new tab with Google Ads platform. It will take
                you directly to the Payments Setup page. When you finish, close that tab to continue working on AMD.
              </Paragraph>
            </CardWithIcon>
          )}
          <Paragraph size="p2">
            See how your campaigns are performing. You can filter by campaign status and dates. Click a campaign if you
            want to make changes or see the current settings.
          </Paragraph>
          <div className="flex items-center justify-between">
            <div className="grid grid-cols-2 gap-3.5">
              <FilterByStatus value={status} emptyLabel="Status" onChange={(value) => setStatus(value)} />
              <FilterByDate value={date} emptyLabel="Date" onChange={(value) => setDate(value)} />
            </div>
            <div>
              <Button variant="light" rounded onClick={() => navigate(CampaignUrls.create)}>
                Create Campaign
              </Button>
            </div>
          </div>
          <CampaignsTable
            campaigns={campaignInfo}
            status={status}
            billingStatus={billingStatus}
            onRowClick={(campaignID: string) => {
              setCampaignId(campaignID);
              navigate(CampaignUrls.edit);
            }}
          />
        </>
      )}
    </Container>
  );
}
