import { useCallback } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { GoalsOptions } from "@/data/constants";
import { RadioField } from "@/components/ui/form";
import { FieldProps, FieldWrapper } from "./BaseField";

const schema = yup
  .object({
    goal: yup.string().required()
  })
  .required();

type FormData = {
  goal: string;
};

export function GoalField({ active, handleNext, ...props }: FieldProps) {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    values: {
      goal: "sales_signups"
    }
  });

  const onSubmit = useCallback(
    ({ goal }: FormData) => {
      console.log("SET GOAL", goal);
      handleNext!();
    },
    [handleNext]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FieldWrapper
        label="1. What do you want to accomplish from this Google ad?"
        description="If you have more than one goal, create separate campaigns for each of them."
        active={active}
        valid={isValid}
        {...props}
      >
        <RadioField options={GoalsOptions} {...register("goal")} error={errors.goal?.message} variant="horizontal" />
      </FieldWrapper>
    </form>
  );
}
