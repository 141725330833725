import { PropsWithChildren } from "react";

import { Button, clsx, MessageCard, Spinner } from "@/components/ui";
import { Paragraph } from "@/components/ui/typography";

export type FieldProps = {
  active?: boolean;
  handleNext?: () => void;
  handleBack?: () => void;
  valid?: boolean;
  nextSubmitButton?: boolean;
};

type FieldWrapperProps = FieldProps & {
  label: string;
  description?: string;
  className?: string;
  showButtons?: boolean;
  isLoading?: boolean;
};

export function FieldWrapper({
  label,
  description,
  className,
  children,
  active = false,
  valid = false,
  handleNext,
  handleBack,
  showButtons = true,
  isLoading = false,
  nextSubmitButton
}: PropsWithChildren<FieldWrapperProps>) {
  return (
    <div className={clsx("space-y-[46px]", active && "animate-fadeIn")}>
      <MessageCard active={active} className={clsx("max-w-2xl", className)}>
        <div className="space-y-4">
          <Paragraph weight="semi" className="flex flex-wrap items-center gap-5 text-[18px]">
            {isLoading && <Spinner />} {label}
          </Paragraph>
          {description && <Paragraph size="p2">{description}</Paragraph>}
        </div>
      </MessageCard>
      {active && (
        <>
          {children}
          {showButtons && (
            <ButtonActions
              handleNext={handleNext}
              handleBack={handleBack}
              valid={valid}
              nextSubmitButton={nextSubmitButton}
            />
          )}
        </>
      )}
    </div>
  );
}

export function ButtonActions({
  handleNext = () => null,
  handleBack = () => null,
  valid = false,
  nextSubmitButton = true
}: FieldProps) {
  return (
    <div className="flex max-w-2xl items-center justify-between">
      <Button variant="outline" rounded onClick={handleBack}>
        Back
      </Button>
      <Button
        variant="light"
        type={nextSubmitButton ? "submit" : "button"}
        rounded
        onClick={handleNext}
        disabled={!valid}
      >
        Next
      </Button>
    </div>
  );
}
