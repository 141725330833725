import { useCallback } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { LanguageOptions } from "@/data/constants";
import { SelectField } from "@/components/ui/form";
import { FieldProps, FieldWrapper } from "./BaseField";
import { useData } from "@/hooks/useData";

const schema = yup
  .object({
    language_code: yup.string().required()
  })
  .required();

type FormData = {
  language_code: string;
};

export function LanguageCodeField({ handleNext, ...props }: FieldProps) {
  const { setLanguage_code, language_code } = useData();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    values: {
      language_code
    }
  });

  const onSubmit = useCallback(
    ({ language_code }: FormData) => {
      setLanguage_code(language_code);
      handleNext!();
    },
    [handleNext, setLanguage_code]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FieldWrapper label="4. Select language of your ad" valid={isValid} {...props}>
        <SelectField
          options={LanguageOptions}
          {...register("language_code")}
          error={errors.language_code?.message}
          className="max-w-2xl"
        />
      </FieldWrapper>
    </form>
  );
}
