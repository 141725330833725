import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { SubmitButton, PasswordField } from "@/components/ui/form";
import { Card } from "@/components/ui";
import { AuthUrls } from "@/routes/urls";

// const GeneralErrorMessage = "Something went wrong, please try again";

const schema = yup
  .object({
    password: yup.string().required(),
    confirm: yup.string().oneOf([yup.ref("password"), undefined], "Passwords must match")
  })
  .required();

type FormData = {
  password: string;
  confirm?: string;
};

export default function ChangePasswordForm({ hash }: { hash: string }) {
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>({
    resolver: yupResolver(schema)
  });

  const onSubmit = useCallback(
    async ({ password }: FormData) => {
      setErrorMsg("");
      console.log("PASSWORD SET", password, hash);
      navigate(AuthUrls.changePasswordSuccess);
    },
    [navigate, hash]
  );

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)} className="space-y-[32px]">
      {errorMsg && <Card variant="warning">{errorMsg}</Card>}
      <PasswordField placeholder="Create Password" {...register("password")} error={errors.password?.message} />
      <PasswordField placeholder="Confirm Password" {...register("confirm")} error={errors.confirm?.message} />
      <SubmitButton isLoading={false} variant="light" rounded className="w-full py-3">
        Reset Password
      </SubmitButton>
    </form>
  );
}
