import { useGetCampaignSettingsQuery } from "@/data/api";
import { useEffect, useMemo } from "react";
import { useData } from "@/hooks/useData";

export function useCampaign() {
  const {
    campaignId,
    commonPayload,
    setKeyword_themes,
    setBudget,
    setGeo_location,
    setHeadline_1,
    setHeadline_2,
    setHeadline_3,
    setDesc_1,
    setDesc_2,
    setLanding_page,
    setLanguage_code,
    setCountry_code
  } = useData();

  const payload = useMemo(
    () => ({
      campaignId,
      ...commonPayload
    }),
    [campaignId, commonPayload]
  );

  const requestAvailable = useMemo(() => {
    const { token, refreshToken, customerId, campaignId } = payload;
    return [token, refreshToken !== undefined, customerId, campaignId].every(Boolean);
  }, [payload]);

  const {
    data: [campaignInfo] = [],
    isSuccess,
    isLoading
  } = useGetCampaignSettingsQuery(payload, {
    skip: !requestAvailable
  });

  useEffect(() => {
    if (campaignInfo) {
      setKeyword_themes(campaignInfo.keyword_themes);
      setGeo_location(campaignInfo.geo_targets);
      setHeadline_1(campaignInfo.headline_1);
      setHeadline_2(campaignInfo.headline_2);
      setHeadline_3(campaignInfo.headline_3);
      setDesc_1(campaignInfo.desc_1);
      setDesc_2(campaignInfo.desc_2);
      setBudget(`${campaignInfo.budget_micros}`);
      setLanguage_code(campaignInfo.language_code);
      setCountry_code(campaignInfo.country_code);
      setLanding_page(campaignInfo.final_url);
    }
  }, [campaignInfo]);

  return {
    campaignInfo,
    payload,
    requestAvailable,
    campaignId,
    isSuccess,
    isLoading
  };
}
