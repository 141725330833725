import { Button, Spinner, ButtonProps, clsx } from "@/components/ui";

export type SubmitButtonProps = ButtonProps & {
  isLoading?: boolean;
};

export function SubmitButton({
  isLoading = false,
  children,
  className,
  variant = "dark",
  ...props
}: SubmitButtonProps) {
  return (
    <Button
      variant={variant}
      type="submit"
      className={clsx("flex items-center justify-center gap-2", className)}
      {...props}
    >
      <>
        {isLoading && <Spinner />}
        {children}
      </>
    </Button>
  );
}
