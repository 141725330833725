import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@/data/store";
import * as auth from "@/utils/auth";
import { RefreshToken } from "@/data/types";

type UiState = {
  token?: string;
  refreshToken: RefreshToken;
  customerId?: string | number;
};

const initialState: () => UiState = () => ({
  token: auth.getToken(),
  refreshToken: undefined
});

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string | undefined>) => {
      state.token = action.payload;
      auth.setToken(action.payload);
    },
    setRefreshToken: (state, action: PayloadAction<RefreshToken>) => {
      state.refreshToken = action.payload;
    },
    setCustomerId: (state, action: PayloadAction<UiState["customerId"]>) => {
      state.customerId = action.payload;
    }
  }
});

export const { setToken, setRefreshToken, setCustomerId } = uiSlice.actions;

export const selectIsAuthenticated = (state: RootState) => !!state.ui.token;
export const selectToken = (state: RootState) => state.ui.token;
export const selectRefreshToken = (state: RootState) => state.ui.refreshToken;
export const selectCustomerId = (state: RootState) => state.ui.customerId;
