import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { TextField, SubmitButton } from "@/components/ui/form";
import { Card } from "@/components/ui";
import { AuthUrls } from "@/routes/urls";

// const GeneralErrorMessage = "Something went wrong, please try again";

const schema = yup
  .object({
    email: yup.string().email().required()
  })
  .required();

type FormData = {
  email: string;
};

export default function PasswordResetForm() {
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>({
    resolver: yupResolver(schema)
  });

  const onSubmit = useCallback(async ({ email }: FormData) => {
    setErrorMsg("");
    // TODO: do real password reset
    console.log("Password reset for ", email);
    navigate(AuthUrls.resetPasswordSuccess);
  }, []);

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)} className="space-y-4 lg:space-y-[38px]">
      {errorMsg && <Card variant="warning">{errorMsg}</Card>}
      <TextField placeholder="Enter E-Mail" {...register("email")} error={errors.email?.message} />
      <SubmitButton variant="light" rounded className="w-full py-3">
        Reset Password
      </SubmitButton>
    </form>
  );
}
