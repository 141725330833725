import ContainerWithAmdBlock from "@/components/ContainerWithAmdBlock";
import { Heading } from "@/components/ui/typography";
import CreateGoogleAdsAccountForm from "@/components/googleads/CreateGoogleAdsAccountForm";

export default function CreateGoogleAdsAccountPage() {
  return (
    <ContainerWithAmdBlock className="max-w-[460px]">
      <Heading.h1>Create Google Ads Account</Heading.h1>
      <CreateGoogleAdsAccountForm />
    </ContainerWithAmdBlock>
  );
}
