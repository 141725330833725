import CreateCampaignLayout from "@/components/Campaigns/CreateCampaignLayout";
import KeywordThemesStep from "@/components/Campaigns/CreateCampaign/KeywordThemesStep";

export default function KeywordThemesPage() {
  return (
    <CreateCampaignLayout>
      <KeywordThemesStep />
    </CreateCampaignLayout>
  );
}
