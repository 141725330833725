import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Heading } from "@/components/ui/typography";
import { Button, Container, RectangleSection } from "@/components/ui";
import { TextField } from "@/components/ui/form";

const schema = yup
  .object({
    name: yup.string().required(),
    email: yup.string().email().required(),
    message: yup.string().required()
  })
  .required();

type FormData = {
  name: string;
  email: string;
  message: string;
};

export default function ContactForm() {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>({
    resolver: yupResolver(schema)
  });

  const onSubmit = async (values: FormData) => {
    console.log("Submitting...", values);
  };

  return (
    <RectangleSection variant="v2" circles={false} className="mb-20">
      <Container id="contact" className="space-y-8 py-[94px] lg:space-y-[46px]">
        <Heading size="h1" as="h2" className="text-white">
          Contact
        </Heading>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 lg:space-y-10">
          <div className="space-y-4 lg:grid lg:grid-cols-2 lg:gap-x-[100px] lg:space-y-0">
            <TextField placeholder="Name" {...register("name")} error={errors.name?.message} />
            <TextField placeholder="E-Mail" {...register("email")} error={errors.email?.message} />
          </div>
          <TextField placeholder="Comment" {...register("message")} error={errors.message?.message} />
          <div className="!lg:mt-[60px] !mt-8 flex justify-center">
            <Button type="submit" variant="light" size="lg" rounded className="w-full py-3 lg:max-w-[420px]">
              Submit
            </Button>
          </div>
        </form>
      </Container>
    </RectangleSection>
  );
}
