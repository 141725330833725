import { Paragraph } from "@/components/ui/typography";
import { Card } from "@/components/ui";
import { useData } from "@/hooks/useData";

type AdPreviewBlockProps = {
  headlines: string[];
  descriptions: string[];
};

export default function AdPreviewBlock({ headlines, descriptions }: AdPreviewBlockProps) {
  const { landing_page } = useData();

  return (
    <div className="space-y-4">
      <Paragraph>Ad preview:</Paragraph>
      <Card className="space-y-2.5">
        <Paragraph>
          <Paragraph as="span" weight="semi">
            Ad •
          </Paragraph>{" "}
          <Paragraph as="span" size="p2">
            {landing_page}
          </Paragraph>
        </Paragraph>
        <Paragraph>{headlines.filter(Boolean).join(" | ")}</Paragraph>
        <Paragraph size="p2">{descriptions.filter(Boolean).join(" ")}</Paragraph>
      </Card>
    </div>
  );
}
