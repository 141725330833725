import { Button, ButtonProps } from "./button";
import { clsx } from "./clsx";
import { Spinner } from "./spinner";

export type TagButtonProps = Omit<ButtonProps, "variant"> & {
  rounded?: boolean;
  variant?: "light" | "dark";
  loading?: boolean;
};

export function TagButton({
  loading,
  children,
  className,
  variant = "dark",
  rounded = false,
  ...props
}: TagButtonProps) {
  return (
    <Button
      {...props}
      className={clsx(
        "flex items-center justify-between gap-2.5 px-2.5 py-1 font-semibold",
        variant === "light" && "border border-white/60 bg-white/60",
        rounded && "px-5",
        className
      )}
      variant={variant}
      rounded={rounded}
    >
      <>
        {loading && <Spinner />}
        {children}
      </>
    </Button>
  );
}
