import { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";

import { clsx, Container, SectionCard } from "@/components/ui";
import { Heading, Paragraph } from "@/components/ui/typography";
import { CampaignUrls } from "@/routes/urls";

export type CreateCampaignLayoutProps = PropsWithChildren & {
  className?: string;
  heading?: string;
};

export default function CreateCampaignLayout({ children, className, heading }: CreateCampaignLayoutProps) {
  const navigate = useNavigate();

  return (
    <Container className="space-y-8 py-9 text-white">
      <div>
        <button className="flex items-center gap-2" onClick={() => navigate(CampaignUrls.list)}>
          <ChevronLeftIcon className="size-[22px]" />
          <Paragraph size="u2" weight="semi" as="span">
            Campaigns Performance
          </Paragraph>
        </button>
      </div>
      <Heading>{heading || "Create New Campaign"}</Heading>
      <SectionCard className={clsx("min-h-[680px] space-y-[50px]", className)}>{children}</SectionCard>
    </Container>
  );
}
