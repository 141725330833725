import { Button, clsx } from "@/components/ui";
import { FieldWrapper } from "./BaseField";

export function IntroMessage({ active = false, onClick = () => null }: { active?: boolean; onClick?: () => void }) {
  return (
    <FieldWrapper
      active={active}
      label="Hello! My name is Alex. I'm here to assist you in creating your Google Ads campaign."
      description=" We'll go through a few simple steps to ensure your campaign is set up for success. Click the button below to
            get started and see how easy it can be!"
      className={clsx(active && "mx-auto")}
      showButtons={false}
    >
      <div className="mx-auto flex max-w-2xl items-center justify-center">
        <Button onClick={onClick} variant="light">
          Lets create a campaign
        </Button>
      </div>
    </FieldWrapper>
  );
}
