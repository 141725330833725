import { Select, SelectProps } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

import { clsx } from "../clsx";
import { CommonControlStyles, FieldLayout, FieldLayoutProps } from "./field-layout";
import React, { forwardRef } from "react";

export type Option = { value: string; label: string };

export type SelectFieldProps = FieldLayoutProps &
  SelectProps & {
    options: Option[];
  };

export const SelectField = forwardRef(
  ({ label, required, error, helpText, disabled, options, className, ...props }: SelectFieldProps, ref) => {
    return (
      <FieldLayout disabled={disabled} label={label} required={required} error={error} helpText={helpText}>
        <div className={clsx("relative", className)}>
          <Select
            ref={ref as React.Ref<HTMLSelectElement>}
            className={clsx(
              CommonControlStyles,
              "appearance-none",
              // Make the text of each option black on Windows
              "*:text-black"
            )}
            {...props}
          >
            {options.map(({ value, label }) => (
              <option value={value} key={`${props.name}:${value}`}>
                {label}
              </option>
            ))}
          </Select>
          <ChevronDownIcon
            className="group pointer-events-none absolute right-2.5 top-3 size-4 fill-white/60"
            aria-hidden="true"
          />
        </div>
      </FieldLayout>
    );
  }
);
