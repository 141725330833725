import ContainerWithAmdBlock from "@/components/ContainerWithAmdBlock";
import { Heading, Paragraph } from "@/components/ui/typography";
import Link from "@/components/Link";
import { AuthUrls } from "@/routes/urls";
import PasswordResetForm from "@/components/auth/PasswordResetForm";

export default function PasswordResetPage() {
  return (
    <ContainerWithAmdBlock className="max-w-[460px] lg:pt-44">
      <div className="space-y-4">
        <Heading.h1>Forgot Password?</Heading.h1>
        <Paragraph>No worries, we’ll send you reset instructions.</Paragraph>
      </div>
      <PasswordResetForm />
      <Paragraph className="text-center">
        <Link to={AuthUrls.login}>
          Return to <strong>Login</strong>
        </Link>
      </Paragraph>
    </ContainerWithAmdBlock>
  );
}
