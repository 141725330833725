import { forwardRef, useState } from "react";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

import { TextField, TextFieldProps } from "./text-field";

export type PasswordFieldProps = Omit<TextFieldProps, "type">;

export const PasswordField = forwardRef((props: PasswordFieldProps, ref) => {
  const [secure, setSecure] = useState(true);

  return (
    <TextField
      {...props}
      type={secure ? "password" : "text"}
      suffix={
        <button type="button" onClick={() => setSecure(!secure)}>
          {secure ? <EyeIcon className="size-5" /> : <EyeSlashIcon className="size-5" />}
        </button>
      }
      ref={ref}
    />
  );
});
