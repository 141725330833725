import { useNavigate } from "react-router-dom";

import CreateCampaignLayout from "@/components/Campaigns/CreateCampaignLayout";
import { Heading } from "@/components/ui/typography";
import { Button } from "@/components/ui";
import { CampaignUrls } from "@/routes/urls";

export default function CreateCampaignSuccessPage() {
  const navigate = useNavigate();

  return (
    <CreateCampaignLayout className="flex h-full flex-col items-center justify-center">
      <div className="text-[57px]">🎉</div>
      <Heading>Ad Successfully completed!</Heading>
      <Button variant="light" rounded onClick={() => navigate(CampaignUrls.list)}>
        Go to Campaigns
      </Button>
    </CreateCampaignLayout>
  );
}
