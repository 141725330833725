import { Heading, Paragraph } from "@/components/ui/typography";

export default function AMDBenefitsBlock() {
  return (
    <div className="relative min-h-[800px] max-w-[604px] space-y-7 overflow-hidden rounded-[20px] rounded-tl-[90px] bg-[url(/abstract-bg.png)] px-5 py-10 pl-10 text-white lg:px-[50px] lg:py-[30px]">
      <Heading.h2>Unlock the Power of Effective Advertising with AMD Platform!</Heading.h2>
      <Paragraph>
        Boost your business with our cutting-edge platform designed to create, manage, and optimize Google Ads
        effortlessly.
      </Paragraph>
      <Paragraph>
        Experience seamless integration, real-time performance tracking, and advanced targeting tools that maximize your
        ad spend and drive results.
      </Paragraph>
      <Paragraph weight="semi">Start transforming your advertising campaigns today!</Paragraph>

      <img
        className="absolute bottom-0 left-0 w-full"
        src="/amd-benefits-path.svg"
        alt="Unlock the Power of Effective Advertising with AMD Platform Illustration"
      />
      <img
        className="absolute bottom-0 left-[25px] w-[94%]"
        src="/amd-benefits.svg"
        alt="Unlock the Power of Effective Advertising with AMD Platform Illustration"
      />
    </div>
  );
}
