import { clsx } from "./clsx";

export function Spinner({ className }: { className?: string }) {
  return (
    <svg
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx("animate-spin fill-icon-dark", className)}
    >
      <path d="M11 5.14644C12.4211 5.14644 13.5732 3.99438 13.5732 2.57324C13.5732 1.15209 12.4211 3.05176e-05 11 3.05176e-05C9.57882 3.05176e-05 8.42676 1.15209 8.42676 2.57324C8.42676 3.99438 9.57882 5.14644 11 5.14644Z" />
      <path d="M11.0003 22.0003C11.8529 22.0003 12.5441 21.3091 12.5441 20.4565C12.5441 19.6039 11.8529 18.9128 11.0003 18.9128C10.1477 18.9128 9.45654 19.6039 9.45654 20.4565C9.45654 21.3091 10.1477 22.0003 11.0003 22.0003Z" />
      <path d="M4.67738 7.50775C5.9565 7.50775 6.99343 6.47081 6.99343 5.19169C6.99343 3.91257 5.9565 2.87564 4.67738 2.87564C3.39826 2.87564 2.36133 3.91257 2.36133 5.19169C2.36133 6.47081 3.39826 7.50775 4.67738 7.50775Z" />
      <path d="M17.3232 19.1227C18.0338 19.1227 18.6098 18.5467 18.6098 17.8361C18.6098 17.1256 18.0338 16.5495 17.3232 16.5495C16.6127 16.5495 16.0366 17.1256 16.0366 17.8361C16.0366 18.5467 16.6127 19.1227 17.3232 19.1227Z" />
      <path d="M2.05806 13.5734C3.1947 13.5734 4.11613 12.6519 4.11613 11.5153C4.11613 10.3787 3.1947 9.45724 2.05806 9.45724C0.921427 9.45724 0 10.3787 0 11.5153C0 12.6519 0.921427 13.5734 2.05806 13.5734Z" />
      <path d="M19.9412 12.5431C20.5093 12.5431 20.9698 12.0825 20.9698 11.5145C20.9698 10.9464 20.5093 10.4858 19.9412 10.4858C19.3731 10.4858 18.9126 10.9464 18.9126 11.5145C18.9126 12.0825 19.3731 12.5431 19.9412 12.5431Z" />
      <path d="M3.40385 16.5649C2.70002 17.2687 2.70002 18.4075 3.40385 19.1114C4.10685 19.8152 5.24734 19.8152 5.95034 19.1114C6.65417 18.4075 6.65417 17.2687 5.95034 16.5649C5.24734 15.8602 4.10768 15.8535 3.40385 16.5649Z" />
      <path d="M17.3218 5.96324C17.7478 5.96324 18.0932 5.61785 18.0932 5.19178C18.0932 4.76571 17.7478 4.42032 17.3218 4.42032C16.8957 4.42032 16.5503 4.76571 16.5503 5.19178C16.5503 5.61785 16.8957 5.96324 17.3218 5.96324Z" />
    </svg>
  );
}
