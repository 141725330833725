import { useNavigate } from "react-router-dom";

import { useData } from "@/hooks/useData";
import { useEffect } from "react";
import { AuthUrls } from "@/routes/urls";

export default function LogoutPage() {
  const navigate = useNavigate();
  const { cleanCookies, logout } = useData();

  useEffect(() => {
    cleanCookies();
    logout();
    navigate(AuthUrls.login);
  }, []);

  return null;
}
