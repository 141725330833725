import React, { forwardRef } from "react";

import { clsx } from "../clsx";
import { Paragraph } from "../typography";
import { FieldLayout, FieldLayoutProps } from "./field-layout";
import { Option } from "./select-field";
import { LabelStyles } from "@/components/ui/form/label";

export type RadioFieldProps = FieldLayoutProps &
  React.HTMLProps<HTMLInputElement> & {
    options: Option[];
    variant?: "vertical" | "horizontal";
  };

export const RadioField = forwardRef(
  (
    { label, required, error, helpText, disabled, options, className, variant = "vertical", ...props }: RadioFieldProps,
    ref
  ) => {
    return (
      <FieldLayout disabled={disabled} label={label} required={required} error={error} helpText={helpText}>
        <div className={clsx("flex flex-col gap-4", variant === "horizontal" && "lg:flex-row", className)}>
          {options.map((option) => (
            <label key={option.value} className="flex items-center gap-6 px-2">
              <input
                type="radio"
                value={option.value}
                ref={ref as React.Ref<HTMLInputElement>}
                className={clsx(
                  "peer size-[12px] appearance-none rounded-full outline outline-offset-[5px] outline-icon-light ring-[5px] ring-transparent ring-offset-0",
                  "checked:bg-[#D9D9D9] checked:outline-icon-dark checked:ring-icon-dark"
                )}
                {...props}
              />
              <Paragraph as="span" className={clsx(LabelStyles, "font-normal opacity-60 peer-checked:opacity-100")}>
                {option.label}
              </Paragraph>
            </label>
          ))}
        </div>
      </FieldLayout>
    );
  }
);
