import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useData } from "@/hooks/useData";
import { TextField } from "@/components/ui/form";
import { FieldProps, FieldWrapper } from "./BaseField";
import { useAccountInfo } from "@/hooks/useAccountInfo";

const schema = yup
  .object({
    landing_page: yup.string().required()
  })
  .required();

type FormData = {
  landing_page: string;
};

export function LandingPageField({ handleNext, ...props }: FieldProps) {
  const { setLanding_page, landing_page } = useData();
  const { finalUrl } = useAccountInfo();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    values: {
      landing_page
    }
  });

  useEffect(() => {
    if (finalUrl) {
      setValue("landing_page", finalUrl);
    }
  }, [finalUrl, setValue]);

  const onSubmit = useCallback(
    ({ landing_page }: FormData) => {
      setLanding_page(landing_page);
      handleNext!();
    },
    [handleNext, setLanding_page]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FieldWrapper
        label="3. Tell us where people go after they click your ad"
        description="This might be your homepage, or more specific page. Copy the page address (URL) and paste it here to make sure there are no mistakes."
        valid={isValid}
        {...props}
      >
        <TextField
          placeholder="https://www.example.com"
          {...register("landing_page")}
          error={errors.landing_page?.message}
          className="max-w-2xl"
        />
      </FieldWrapper>
    </form>
  );
}
