import { Button } from "@/components/ui";
import { Heading, Paragraph } from "@/components/ui/typography";
import Link from "@/components/Link";
import ContainerWithAmdBlock from "@/components/ContainerWithAmdBlock";
import { GoogleAdsUrls, PagesUrls } from "@/routes/urls";

export default function GetStarted() {
  return (
    <ContainerWithAmdBlock>
      <Heading.h1>Get in the first page of Google!</Heading.h1>
      <Paragraph>
        <strong>AMD</strong> is a free app that will help you sell more by promoting your business on Google Search,
        Google Maps, Youtube, Gmail and Google partner websites.{" "}
      </Paragraph>
      <div className="grid grid-cols-2 gap-4 lg:flex lg:flex-row lg:gap-7">
        <Link to={GoogleAdsUrls.googleads}>
          <Button variant="light" rounded className="w-full lg:w-auto">
            Start
          </Button>
        </Link>
        <Link to={PagesUrls.learnMore}>
          <Button variant="dark" rounded className="w-full px-2 lg:w-auto">
            Learn More
          </Button>
        </Link>
      </div>
    </ContainerWithAmdBlock>
  );
}
