import { JSX, PropsWithChildren } from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";

import { clsx } from "./clsx";
import { Spinner } from "./spinner";

export type CardProps = PropsWithChildren & {
  className?: string;
  variant?: "dark" | "warning";
};

export function Card({ className, children, variant = "dark" }: CardProps) {
  return (
    <div
      className={clsx(
        "rounded-xl border border-white/20 px-5 py-4",
        variant === "dark" && "bg-white/8 text-white",
        variant === "warning" && "bg-amd-card text-dark-purple",
        className
      )}
    >
      {children}
    </div>
  );
}

type DefaultIcon = "warning" | "warning-light" | "spinner";

export type CardWithIconProps = CardProps & {
  icon: JSX.Element | DefaultIcon;
};

const DefaultIconMap: Record<DefaultIcon, JSX.Element> = {
  warning: <ExclamationTriangleIcon className="size-6 shrink-0 text-dark-purple" />,
  "warning-light": <ExclamationTriangleIcon className="size-6 shrink-0 text-white" />,
  spinner: <Spinner className="size-6 shrink-0 fill-dark-purple" />
};

export function CardWithIcon({ icon, className, children, ...props }: CardWithIconProps) {
  return (
    <Card className={clsx("flex items-center gap-2.5", className)} {...props}>
      {typeof icon === "string" ? DefaultIconMap[icon] : icon}
      {children}
    </Card>
  );
}

export type SectionCardProps = PropsWithChildren & {
  className?: string;
  opacity?: boolean;
};

export function SectionCard({ children, className, opacity = true }: SectionCardProps) {
  return (
    <div
      className={clsx(
        "rounded-2xl border border-white/20 px-2.5 py-4 lg:px-[50px] lg:py-[50px]",
        opacity ? "bg-white/10 text-white" : "bg-white text-black",
        className
      )}
    >
      {children}
    </div>
  );
}

export type MessageCardProps = SectionCardProps & {
  active?: boolean;
};

export function MessageCard({ className, active, ...props }: MessageCardProps) {
  return (
    <SectionCard
      {...props}
      className={clsx("rounded-[30px] lg:py-[30px]", active && "rounded-bl-none bg-white text-amd-dark", className)}
    />
  );
}
