import ContainerWithAmdBlock from "@/components/ContainerWithAmdBlock";
import { Heading, Paragraph } from "@/components/ui/typography";
import Link from "@/components/Link";
import { AuthUrls } from "@/routes/urls";
import ChangePasswordForm from "@/components/auth/ChangePasswordForm";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Spinner } from "@/components/ui";

export default function ChangePasswordPage() {
  const { hash } = useParams();
  const [checkedHash, setCheckedHash] = useState("");

  useEffect(() => {
    if (hash) {
      setTimeout(() => {
        setCheckedHash(hash);
      }, 3000);
    }
  }, [hash]);

  return (
    <ContainerWithAmdBlock className="max-w-[460px] lg:pt-44">
      <div className="space-y-4">
        <Heading.h1>Set New Password</Heading.h1>
        <Paragraph>Your new password must be different to previously one.</Paragraph>
      </div>
      {checkedHash && <ChangePasswordForm hash={checkedHash} />}
      {!checkedHash && (
        <div className="flex items-center gap-5">
          <Spinner />
          <Paragraph>Loading...</Paragraph>
        </div>
      )}
      <Paragraph className="text-center">
        <Link to={AuthUrls.login}>
          Return to <strong>Login</strong>
        </Link>
      </Paragraph>
    </ContainerWithAmdBlock>
  );
}
