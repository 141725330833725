import { PropsWithChildren, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useData } from "@/hooks/useData";
import { AuthUrls } from "@/routes/urls";

export default function RequireAuth({ children }: PropsWithChildren) {
  const { isAuthenticated } = useData();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(AuthUrls.login, { replace: true });
    }
  }, [isAuthenticated, navigate]);

  return children;
}
