import React from "react";
import { Select } from "@/components/ui/form/controls";

export const StatusOptions = ["All", "All active", "All but removed"] as const;

export type StatusOption = (typeof StatusOptions)[number];

export type FilterByStatusProps = {
  value?: string;
  onChange: (value: StatusOption) => void;
  emptyLabel?: string;
};

export function FilterByStatus({ value, onChange, emptyLabel }: FilterByStatusProps) {
  return (
    <Select
      onChange={(event: React.ChangeEvent<HTMLSelectElement>) => onChange(event.target.value as StatusOption)}
      value={value}
      aria-label="Filter table by campaign status"
    >
      {emptyLabel && <option value="">{emptyLabel}</option>}
      {StatusOptions.map((option: StatusOption) => (
        <option value={option} key={option}>
          {option}
        </option>
      ))}
    </Select>
  );
}
