import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ContainerWithAmdBlock from "@/components/ContainerWithAmdBlock";
import { Heading, Paragraph } from "@/components/ui/typography";
import { Button, clsx, GoogleButton, SectionCard, SectionCardProps } from "@/components/ui";
import { useAuthenticateGoogle } from "@/hooks/useAuthenticateGoogle";
import { useData } from "@/hooks/useData";
import { CampaignUrls, GoogleAdsUrls } from "@/routes/urls";

function Card({ className, ...props }: SectionCardProps) {
  return <SectionCard className={clsx("flex flex-col items-center gap-7 lg:px-5 lg:py-7", className)} {...props} />;
}

export default function GoogleAdsPage() {
  const navigate = useNavigate();
  const { authenticateGoogle, createAccount } = useAuthenticateGoogle();
  const { refreshToken, customerId } = useData();

  useEffect(() => {
    if (refreshToken === undefined || customerId === undefined) {
      return;
    }
    if (customerId !== 0) {
      console.log("user has an Ads account but no refresh token");
      // redirect user to the Reporting page
      navigate(CampaignUrls.list);
    } else if (refreshToken === 1) {
      console.log("user has a refresh token");
      // redirect user to the accessible accounts page
      navigate(GoogleAdsUrls.accounts);
    } else if (refreshToken === 0 && customerId === 0) {
      console.log("New user that does not have refresh token or Google Ads account");
    }
  }, [customerId, navigate, refreshToken]);

  return (
    <ContainerWithAmdBlock>
      <Heading.h1>Let's get you set up!</Heading.h1>
      <div className="grid grid-cols-1 gap-5 lg:grid-cols-2">
        <Card>
          <img src="/googleads.svg" alt="Google Ads Logo" />
          <Paragraph weight="semi" size="u1">
            Already have an account?
          </Paragraph>
          <div className="space-y-6">
            <Paragraph weight="semi" size="p3">
              Connect to Google Ads.
            </Paragraph>
            <Paragraph size="p3">Connect your account and manage it from our app!</Paragraph>
            <Paragraph size="p3">
              When giving us permission to connect to your account, you will see that Google uses Adwords to refer to
              Google Ads.
            </Paragraph>
            <Paragraph size="p3">You can disconnect your account anytime you want.</Paragraph>
          </div>
          <GoogleButton className="w-full justify-center px-5" onClick={() => authenticateGoogle()}>
            Login With Google
          </GoogleButton>
        </Card>
        <Card>
          <div className="flex items-center gap-[25px]">
            <img src="/googleads.svg" alt="Google Ads Logo" />
            <img src="/user-plus.svg" alt="+ User Image" />
          </div>
          <Paragraph weight="semi" size="u1">
            Don’t have an account?
          </Paragraph>
          <div className="space-y-6">
            <Paragraph weight="semi" size="p3">
              Create a Google Ads Account.
            </Paragraph>
            <Paragraph size="p3">Don’t worry! We can create one for you.</Paragraph>
            <Paragraph size="p3">
              You will need to select an account name, your email, the currency you want to use, and the time zone.
            </Paragraph>
          </div>
          <Button variant="light" className="mt-auto w-full" rounded onClick={createAccount}>
            Create account
          </Button>
        </Card>
      </div>
    </ContainerWithAmdBlock>
  );
}
