import ContainerWithAmdBlock from "@/components/ContainerWithAmdBlock";
import { Heading, Paragraph } from "@/components/ui/typography";
import LoginForm from "@/components/auth/LoginForm";
import { Divider, GoogleButton } from "@/components/ui";
import Link from "@/components/Link";
import { AuthUrls } from "@/routes/urls";

export default function LoginPage() {
  return (
    <ContainerWithAmdBlock className="max-w-[460px]">
      <Heading.h1>Login to your account</Heading.h1>
      <LoginForm />
      <Divider>
        <Paragraph size="p2">or continue</Paragraph>
      </Divider>
      <GoogleButton className="w-full justify-center py-3">Login With Google</GoogleButton>
      <Paragraph className="text-center">
        Don’t have an account?{" "}
        <Link to={AuthUrls.signup}>
          <strong>Sign Up</strong>
        </Link>
      </Paragraph>
    </ContainerWithAmdBlock>
  );
}
