import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useAppSelector } from "@/data/hooks";
import { selectIsAuthenticated } from "@/data/ui";
import { useLoginMutation } from "@/data/api";

import { TextField, SubmitButton, PasswordField } from "@/components/ui/form";
import { Card } from "@/components/ui";
import Link from "@/components/Link";
import { Paragraph } from "@/components/ui/typography";
import { AuthUrls } from "@/routes/urls";

const GeneralErrorMessage = "Something went wrong, please try again";

const schema = yup
  .object({
    username: yup.string().required(),
    password: yup.string().required()
  })
  .required();

type FormData = {
  username: string;
  password: string;
};

export default function LoginForm() {
  const navigate = useNavigate();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const [loginUser, { isLoading }] = useLoginMutation();
  const [errorMsg, setErrorMsg] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>({
    resolver: yupResolver(schema)
  });

  // if user has token, redirect user to googleads page
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/googleads");
    }
  }, [isAuthenticated, navigate]);

  const onSubmit = useCallback(
    async ({ username, password }: FormData) => {
      setErrorMsg("");
      try {
        await loginUser({ username, password }).unwrap();
      } catch (err) {
        setErrorMsg(
          (err as { data: { non_field_errors: string[] } }).data.non_field_errors?.join("\n") || GeneralErrorMessage
        );
      }
    },
    [loginUser]
  );

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)} className="space-y-4 lg:space-y-[38px]">
      {errorMsg && <Card variant="warning">{errorMsg}</Card>}
      <TextField placeholder="Username" {...register("username")} error={errors.username?.message} />
      <PasswordField
        placeholder="Password"
        {...register("password")}
        error={errors.password?.message}
        helpText={
          <Paragraph className="text-right text-white">
            <Link to={AuthUrls.resetPassword}>Forgot password?</Link>
          </Paragraph>
        }
      />
      <SubmitButton isLoading={isLoading} variant="light" rounded className="w-full py-3">
        Login
      </SubmitButton>
    </form>
  );
}
