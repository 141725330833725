import { Heading, Paragraph } from "@/components/ui/typography";
import { Button, SectionCard } from "@/components/ui";
import { ArrowDownIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
import Link from "@/components/Link";
import { PagesUrls } from "@/routes/urls";

export default function DominateHero() {
  return (
    <>
      <Heading.h1 className="text-center text-white lg:text-left">Dominate Google Ads with Ease</Heading.h1>
      <SectionCard className="space-y-9">
        <Heading.h2>Managing adwords is a messy business,</Heading.h2>
        <div className="space-y-4">
          <Paragraph size="u2">
            Adman Dan is your dedicated ad expert ready to guide you through Take the pain out of your google ads
            campaign.
          </Paragraph>
          <Paragraph size="u2">
            Adman Dan makes managing your ads easy-peasy. Powered by Dan's super-brilliant mind, from setup to
            oversight, Adman Dan has your back.
          </Paragraph>
        </div>
        <div className="flex flex-col items-center gap-8 lg:flex-row">
          <Paragraph size="u2" className="font-semibold">
            I'm ready to boost my business ads
          </Paragraph>
          <ArrowRightIcon className="hidden size-6 lg:block" />
          <ArrowDownIcon className="size-8 lg:hidden" />
          <Link to={PagesUrls.getStarted}>
            <Button variant="light" rounded size="lg" className="w-full py-3 text-dark-purple lg:w-auto">
              Get Started
            </Button>
          </Link>
        </div>
      </SectionCard>
    </>
  );
}
