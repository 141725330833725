// function to transform user's keyword input into title case
export function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt: string) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function capitalize(s: string): string {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function cleanString(value: string | number): string {
  return String(value).replace(/(.)(?=(\d{3})+$)/g, "$1,");
}

export function budgetPerDay(budget: number): string {
  const perDay = budget / 1000000;
  return `$${perDay} per day`;
}

export function budgetHelpText(budget: number, minClicks: number, maxClicks: number): string {
  const perDay = budget / 1000000;
  const maxPerMonth = Math.ceil(perDay * 30.4);
  const minClicksPerMonth = cleanString(Math.round(minClicks * 30.4));
  const maxClicksPerMonth = cleanString(Math.round(maxClicks * 30.4));
  return `${budgetPerDay(budget)} / ${maxPerMonth} max per month | Between ${minClicksPerMonth} and ${maxClicksPerMonth} potential customers per month`;
}
