import { Paragraph } from "@/components/ui/typography";

export type CharacterCountProps = {
  value?: string | number;
  maxLength: number;
};

export function CharacterCount({ maxLength, value }: CharacterCountProps) {
  const length = (value === undefined ? "" : `${value}`).length;
  return (
    <Paragraph size="p2">
      {length}/{maxLength} characters
    </Paragraph>
  );
}
