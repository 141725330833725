import { ButtonProps, Button } from "./button";
import { clsx } from "./clsx";

export function GoogleButton({ children, className, ...props }: ButtonProps) {
  return (
    <Button className={clsx("flex items-center gap-4", className)} {...props}>
      <>
        <img src="/google_logo.svg" className="size-6" alt="google logo" />
        {children}
      </>
    </Button>
  );
}
