import { PropsWithChildren } from "react";
import { Paragraph } from "@/components/ui/typography";
import { clsx } from "@/components/ui";

type FieldWrapperProps = {
  label: string;
  description?: string;
  className?: string;
};

export default function FieldWrapper({
  label,
  description,
  children,
  className
}: PropsWithChildren<FieldWrapperProps>) {
  return (
    <div className={clsx("space-y-[46px]", className)}>
      <div className="space-y-6">
        <Paragraph className="text-[18px]" weight="semi">
          {label}
        </Paragraph>
        <Paragraph size="p2">{description}</Paragraph>
      </div>
      {children}
    </div>
  );
}
