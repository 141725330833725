import ContainerWithAmdBlock from "@/components/ContainerWithAmdBlock";
import { Heading, Paragraph } from "@/components/ui/typography";
import Link from "@/components/Link";
import { AuthUrls } from "@/routes/urls";
import { Button } from "@/components/ui";
import CongratulationCard from "@/components/CongratulationCard";

export default function ChangePasswordSuccessPage() {
  return (
    <ContainerWithAmdBlock className="max-w-[518px] lg:pt-44">
      <CongratulationCard>
        <Heading.h1>Password Reset</Heading.h1>
        <Paragraph>Your password has been successfully reset. Click below to Login.</Paragraph>
        <Link to={AuthUrls.login} className="!mt-[52px] block">
          <Button variant="light" rounded className="w-full py-3">
            Login
          </Button>
        </Link>
      </CongratulationCard>
    </ContainerWithAmdBlock>
  );
}
