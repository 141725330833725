import { Currency } from "@/data/types";

function toSelectOptions(map_: Record<string, string>) {
  return Object.entries(map_).map(([value, label]) => ({ value: `${value}`, label }));
}

export const Languages = {
  en: "English",
  es: "Spanish",
  pt: "Portuguese"
};

export const LanguageOptions = toSelectOptions(Languages);

export const Countries = {
  US: "United States",
  AR: "Argentina",
  BR: "Brazil"
};

export const CountryCodeOptions = toSelectOptions(Countries);

export const timezoneMap: Record<string, string> = {
  "America/New_York": "US East Coast",
  "America/Los_Angeles": "US West Coast",
  "America/Argentina/Buenos_Aires": "Argentina"
};

export const TimezoneOptions = toSelectOptions(timezoneMap);

export const CurrencyMap: Record<Currency, string> = {
  [Currency.USD]: "US Dollar",
  [Currency.AUD]: "Australian Dollar",
  [Currency.EUR]: "Euro"
};

export const CurrencyOptions = toSelectOptions(CurrencyMap);

export const Goals = {
  calls: "Get more calls",
  sales_signups: "Get more website sales or sign-ups",
  offline_sales: "Get more visits to your physical location"
};

export const GoalsOptions = toSelectOptions(Goals);

export enum CampaignStatus {
  Active = "Active",
  Paused = "Paused",
  Deleted = "Deleted"
}

export const CampaignStatuses = {
  [CampaignStatus.Active]: "Active",
  [CampaignStatus.Paused]: "Paused",
  [CampaignStatus.Deleted]: "Deleted"
};

export const CampaignStatusOptions = toSelectOptions(CampaignStatuses);
