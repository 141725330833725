import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

import { clsx, Container } from "@/components/ui";
import Link from "@/components/Link";
import AuthButton from "./AuthButton";
import NavLink from "./NavLink";
import MobileBackground from "@/components/NavBar/MobileBackground";

export default function NavBar() {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setOpen(false);
  }, [location]);

  return (
    <Container className="px-0 lg:mt-5 lg:px-4">
      <header className="relative z-50 lg:flex lg:items-center lg:justify-between lg:rounded-[20px] lg:bg-white/10 lg:px-10">
        {open && <MobileBackground />}
        <div className="relative z-50 flex items-center justify-between px-5 py-4">
          <Link to="/" className="block">
            <img src="/logo.svg" alt="admandam logo" className="w-[63px] lg:w-[105px]" />
          </Link>
          <button type="button" className="lg:hidden" onClick={() => setOpen(!open)}>
            {open ? <XMarkIcon className="size-6 text-white" /> : <Bars3Icon className="size-6 text-white" />}
          </button>
        </div>
        <nav
          className={clsx(
            "relative z-50 hidden h-screen w-full lg:mr-[84px] lg:flex lg:h-auto lg:w-auto lg:flex-row lg:items-center lg:space-x-10",
            open && "flex flex-col border-t border-t-icon-dark lg:!border-t-0"
          )}
        >
          <NavLink to="/#how-it-works">How It Works?</NavLink>
          <NavLink to="/#benefits">Benefits</NavLink>
          <NavLink to="/#plans">Plans</NavLink>
          <NavLink to="/#contact">Contact</NavLink>
          <AuthButton />
        </nav>
      </header>
    </Container>
  );
}
