import { useData } from "@/hooks/useData";
import { useGetAdRecommendationsQuery } from "@/data/api";

export function useAdCopyRecommendations() {
  const {
    token,
    country_code,
    language_code,
    landing_page,
    geo_location,
    keyword_themes,
    business_name,
    refreshToken,
    customerId
  } = useData();

  const requestNotAllowed = [!token, refreshToken === undefined, !customerId].some(Boolean);

  const { data: { headlines = [], descriptions = [] } = {}, isLoading } = useGetAdRecommendationsQuery(
    {
      token: token!,
      refreshToken,
      customerId: customerId!,
      country_code,
      language_code,
      landing_page,
      business_name,
      geo_target_names: geo_location,
      display_name: keyword_themes
    },
    { skip: requestNotAllowed }
  );

  return {
    headlines,
    descriptions,
    isLoading
  };
}
