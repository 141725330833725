import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Option, RadioField, TextField } from "@/components/ui/form";
import { FieldProps, FieldWrapper } from "./BaseField";
import { useChangeCampaignBudgetMutation, useGetBudgetRecommendationsQuery } from "@/data/api";
import { useData } from "@/hooks/useData";
import { CardWithIcon } from "@/components/ui";
import { budgetHelpText, budgetPerDay } from "@/utils/string";
import { LabelStyles } from "@/components/ui/form/label";
import { Paragraph } from "@/components/ui/typography";
import { useCampaign } from "@/hooks/useCampaign";

// const MIN_BUDGET = 10;
// const MAX_BUDGET = 10000;

const schema = yup
  .object({
    selected_budget: yup.string(),
    custom_budget: yup.string()
  })
  .test("oneOfRequired", "You must use recommended or custom budget", function (value) {
    return !!(value.selected_budget || value.custom_budget);
  });

type FormData = {
  selected_budget?: string;
  custom_budget?: string;
};

const BudgetTypes = ["high", "recommended", "low"] as const;

type BudgetFieldProps = FieldProps & {
  saveImmediately?: boolean;
};

export function BudgetField({ active, handleNext, saveImmediately = false, ...props }: BudgetFieldProps) {
  const [messageWarning, setMessageWarning] = useState("");
  const { campaignInfo, payload, requestAvailable } = useCampaign();
  const {
    token,
    refreshToken,
    country_code,
    language_code,
    customerId,
    geo_location,
    landing_page,
    keyword_themes,
    business_name,
    setBudget
  } = useData();

  const budgetCanBeLoaded = token && refreshToken !== undefined && customerId && geo_location;

  const { data: budget_recommendations, isLoading } = useGetBudgetRecommendationsQuery(
    {
      token: token!,
      refreshToken: refreshToken!,
      customerId: customerId!,
      country_code,
      language_code,
      geo_target_names: geo_location,
      landing_page,
      display_name: keyword_themes,
      business_name
    },
    {
      skip: !budgetCanBeLoaded || !active
    }
  );

  const budgetOptions: Option[] = useMemo(
    () =>
      budget_recommendations
        ? BudgetTypes.map((budgetType) => {
            if (!budget_recommendations[budgetType]) {
              return null;
            }
            return {
              value: `${budget_recommendations[budgetType]}`,
              label: budgetHelpText(
                budget_recommendations[budgetType],
                budget_recommendations[`${budgetType}_min_clicks`],
                budget_recommendations[`${budgetType}_max_clicks`]
              )
            };
          }).filter((i) => !!i)
        : [],
    [budget_recommendations]
  );

  const initialBudget = campaignInfo?.budget_micros
    ? campaignInfo?.budget_micros
    : budgetOptions?.[1]?.value
      ? parseInt(budgetOptions?.[1]?.value)
      : undefined;

  const {
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<FormData>({
    // mode: "onChange",
    resolver: yupResolver(schema),
    values: {
      selected_budget: initialBudget ? `${initialBudget}` : "",
      custom_budget: ""
    }
  });

  const [changeCampaignBudget, { isLoading: changeIsLoading }] = useChangeCampaignBudgetMutation();
  const saveBudget = useCallback(
    async (budget: string) => {
      if (!requestAvailable || !saveImmediately || `${campaignInfo?.budget_micros}` === budget) {
        return;
      }

      setMessageWarning("Changing campaign budget... It can take a few seconds.");
      try {
        await changeCampaignBudget({
          ...payload,
          newBudget: budget,
          budgetId: campaignInfo?.budget_id
        }).unwrap();
        setMessageWarning("");
      } catch (err) {
        console.log(err);
        setMessageWarning("Error when trying to change budget. Please try again.");
      }
    },
    [
      campaignInfo?.budget_id,
      campaignInfo?.budget_micros,
      changeCampaignBudget,
      payload,
      requestAvailable,
      saveImmediately
    ]
  );

  const onSubmit = useCallback(
    ({ selected_budget, custom_budget }: FormData) => {
      setMessageWarning("");
      if (!selected_budget && !custom_budget) {
        setMessageWarning("You must choose one of recommended budgets or fill your own budget");
        return;
      }

      const budget = `${custom_budget ? parseFloat(`${custom_budget}`) * 1000000 : selected_budget!}`;
      setBudget(budget);
      saveBudget(budget).then(() => {
        handleNext!();
      });
    },
    [handleNext, saveBudget, setBudget]
  );

  return (
    <>
      {active && (
        <CardWithIcon icon="warning" variant="warning" className="max-w-3xl">
          You can change the budget anytime you want and stop running the ad whenever you want.
        </CardWithIcon>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <FieldWrapper
          label="You will never get charged more than the budget you set for a month. Daily spending might vary, some days spending more and others less, but the total amount per month will not be higher the total budget for the month."
          description="Every click to your ad is considered a potential customer."
          active={active}
          valid={isValid}
          {...props}
        >
          <div className="space-y-4">
            {saveImmediately && initialBudget && (
              <Paragraph className={LabelStyles}>Current budget: {budgetPerDay(initialBudget)}</Paragraph>
            )}
            <Paragraph className={LabelStyles}>Budget in:</Paragraph>
            <Paragraph size="p2">Every click to your ad is considered a potential customer.</Paragraph>
            {budgetOptions.length > 0 && (
              <RadioField
                options={budgetOptions}
                {...register("selected_budget")}
                error={errors.selected_budget?.message}
              />
            )}
            {isLoading && (
              <CardWithIcon icon="spinner" variant="warning" className="max-w-3xl">
                Fetching recommendations for you ... It can take a few seconds
              </CardWithIcon>
            )}
          </div>
          <TextField
            label="Enter other budget"
            placeholder="Budget per day in USD"
            type="number"
            {...register("custom_budget")}
            error={errors.custom_budget?.message}
            className="max-w-2xl"
          />
        </FieldWrapper>
      </form>
      {messageWarning && (
        <CardWithIcon icon={changeIsLoading ? "spinner" : "warning"} variant="warning" className="max-w-3xl">
          {messageWarning}
        </CardWithIcon>
      )}
    </>
  );
}
