import { Select as HSelect, SelectProps as HSelectProps } from "@headlessui/react";
import { clsx } from "@/components/ui";
import { CommonControlStyles } from "@/components/ui/form/field-layout";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

export type SelectProps = HSelectProps;

export function Select({ className, ...props }: SelectProps) {
  return (
    <div className="relative">
      <HSelect
        className={clsx(
          CommonControlStyles,
          "appearance-none",
          // Make the text of each option black on Windows
          "*:text-black",
          className
        )}
        {...props}
      />
      <ChevronDownIcon
        className="group pointer-events-none absolute right-2.5 top-3 size-4 fill-white/60"
        aria-hidden="true"
      />
    </div>
  );
}
