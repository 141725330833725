import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuthenticateGoogle } from "@/hooks/useAuthenticateGoogle";
import { useData } from "@/hooks/useData";
import { useGetAccountsQuery, useLinkAccountsMutation } from "@/data/api";

import { Heading, Paragraph } from "@/components/ui/typography";
import { Button, CardWithIcon, Table, Th, Td, Tbody, Thead } from "@/components/ui";
import { CampaignUrls } from "@/routes/urls";

const VITE_APP_ADS_MANAGER_ID = import.meta.env.VITE_APP_ADS_MANAGER_ID;

export default function AccessibleCustomers() {
  const navigate = useNavigate();
  const [messageError, setMessageError] = useState("");
  const { token, refreshToken, setCustomerId } = useData();
  const [linkAccounts] = useLinkAccountsMutation();

  const {
    data: accountInfo = [],
    isLoading,
    error
  } = useGetAccountsQuery(
    {
      token: token!,
      refreshToken: refreshToken!
    },
    { skip: !token || !refreshToken }
  );

  const { authenticateGoogle, createAccount } = useAuthenticateGoogle();

  const handleOnClick = useCallback(
    (customerId: string, managerID: string) => {
      setCustomerId(customerId);
      console.log({ managerID, customerId });
      if (managerID !== VITE_APP_ADS_MANAGER_ID && token && refreshToken !== undefined) {
        linkAccounts({ token, refreshToken, customerId });
      }
      navigate(CampaignUrls.list);
    },
    [setCustomerId, token, refreshToken, navigate, linkAccounts]
  );

  const onClickManager = useCallback(() => {
    setMessageError("Metrics cannot be requested for a manager account. To retrieve metrics, select a client account.");
  }, []);

  return (
    <div className="space-y-6">
      <Heading className="mb-4 text-left">Google Ads Accounts</Heading>
      <Paragraph>
        Please select the account you want to manage. If you want to see how your campaigns are performing or
        create/edit campaigns, select the Client account that has those campaigns.
      </Paragraph>

      {isLoading && <CardWithIcon icon="spinner">Fetching your data... It can take a few seconds.</CardWithIcon>}
      {messageError && (
        <CardWithIcon icon="warning" variant="warning">
          {messageError}
        </CardWithIcon>
      )}
      {error && (
        <div>
          <CardWithIcon icon="warning" variant="warning">
            During testing, refresh tokens expire. Please reconnect to Google Ads
          </CardWithIcon>
          <Button variant="light" rounded onClick={() => authenticateGoogle()}>
            Reconnect to Google Ads
          </Button>
        </div>
      )}

      <Button variant="light" rounded onClick={createAccount}>
        Create account
      </Button>

      {accountInfo.length > 0 && (
        <Table>
          <Thead>
            <tr>
              <Th>NAME</Th>
              <Th>TYPE</Th>
              <Th>STATUS</Th>
              <Th>CUSTOMER ID</Th>
              <Th>TIME ZONE</Th>
              <Th>CURRENCY</Th>
              <Th>BILLING STATUS</Th>
              <Th>MANAGER ID</Th>
            </tr>
          </Thead>
          <Tbody>
            {accountInfo.map((item) => (
              <tr
                key={item.customer_id}
                onClick={() => {
                  if (item.account_type === "Client") {
                    handleOnClick(item.customer_id, item.manager_account_linked || item.customer_id);
                  } else {
                    onClickManager();
                  }
                }}
                className="cursor-pointer text-center"
              >
                <Td>{item.description}</Td>
                <Td>{item.account_type}</Td>
                <Td>{item.status}</Td>
                <Td>{item.customer_id}</Td>
                <Td>{item.time_zone}</Td>
                <Td>{item.currency}</Td>
                <Td>{item.billing_status}</Td>
                <Td>{item.manager_account_linked === "0" ? "No Manager Linked" : item.manager_account_linked}</Td>
              </tr>
            ))}
          </Tbody>
        </Table>
      )}
    </div>
  );
}
