import { PropsWithChildren } from "react";

import { clsx } from "./clsx";
import { Paragraph } from "./typography";

export function Table({ children, className }: PropsWithChildren<{ className?: string }>) {
  return (
    <div className="overflow-hidden rounded-t-xl border border-white/20">
      <table className={clsx("w-full table-auto border-collapse", className)}>{children}</table>
    </div>
  );
}

export function Th({ children, className }: PropsWithChildren<{ className?: string }>) {
  return (
    <th className={clsx("border-x border-x-white/20 px-2 py-4", className)}>
      <Paragraph as="span" size="p3">
        {children}
      </Paragraph>
    </th>
  );
}

export function Td({ children, className }: PropsWithChildren<{ className?: string }>) {
  return (
    <td className={clsx("border-x border-x-white/20 px-2 py-4", className)}>
      <Paragraph as="span" size="p3">
        {children}
      </Paragraph>
    </td>
  );
}

export function Tbody({ children, className }: PropsWithChildren<{ className?: string }>) {
  return <tbody className={clsx("", className)}>{children}</tbody>;
}

export function Thead({ children, className }: PropsWithChildren<{ className?: string }>) {
  return <thead className={clsx("bg-white/8", className)}>{children}</thead>;
}
